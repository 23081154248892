import { Box, Tooltip } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import React from 'react';
import DownloadImgSvg from '../../../assets/photo-gallery/DownloadImgSvg';
import FlipImgSvg from '../../../assets/photo-gallery/FlipImgSvg';
import RotateImgSvg from '../../../assets/photo-gallery/RotateImgSvg';

interface ImgEditorControlsProps {
    setImgEditorMode: any;
    handleZoomIn: any;
    handleZoomOut: any;
    handleRotate: any;
    handleFlip: any;
    handleDownload: any;
}

const ImgEditorControls: React.FC<ImgEditorControlsProps> = ({
    setImgEditorMode,
    handleZoomIn,
    handleZoomOut,
    handleRotate,
    handleFlip,
    handleDownload,
}) => {
    return (
        <>
            <Tooltip
                label="Zoom in"
                bg="white"
                color="black"
                borderRadius="8px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
                <Box
                    as="button"
                    position="absolute"
                    bottom="12px"
                    left="20px"
                    bgColor="black"
                    h="48px"
                    w="48px"
                    borderRadius="50%"
                    zIndex={5}
                    onClick={handleZoomIn}
                >
                    <AddIcon w={5} h={5} color="white" />
                </Box>
            </Tooltip>
            <Tooltip
                label="Zoom out"
                bg="white"
                color="black"
                borderRadius="8px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
                <Box
                    as="button"
                    position="absolute"
                    bottom="12px"
                    left="76px"
                    bgColor="black"
                    h="48px"
                    w="48px"
                    borderRadius="50%"
                    zIndex={5}
                    onClick={handleZoomOut}
                >
                    <MinusIcon w={5} h={5} color="white" />
                </Box>
            </Tooltip>
            <Tooltip
                label="Rotate"
                bg="white"
                color="black"
                borderRadius="8px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
                <Box
                    as="button"
                    position="absolute"
                    bottom="12px"
                    left="132px"
                    zIndex={5}
                    onClick={handleRotate}
                >
                    <RotateImgSvg />
                </Box>
            </Tooltip>
            <Tooltip
                label="Flip"
                bg="white"
                color="black"
                borderRadius="8px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
                <Box
                    as="button"
                    position="absolute"
                    bottom="12px"
                    left="188px"
                    zIndex={5}
                    onClick={handleFlip}
                >
                    <FlipImgSvg />
                </Box>
            </Tooltip>
            <Tooltip
                label="Save"
                bg="white"
                color="black"
                borderRadius="8px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
                <Box
                    as="button"
                    position="absolute"
                    bottom="12px"
                    left="244px"
                    zIndex={5}
                    onClick={handleDownload}
                >
                    <DownloadImgSvg />
                </Box>
            </Tooltip>
            <Tooltip
                label="Close"
                bg="white"
                color="black"
                borderRadius="8px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
                <Box
                    as="button"
                    position="absolute"
                    bottom="12px"
                    left="300px"
                    bgColor="#11111199"
                    h="48px"
                    w="48px"
                    borderRadius="50%"
                    zIndex={5}
                    onClick={() => setImgEditorMode(false)}
                >
                    <AddIcon
                        w={5}
                        h={5}
                        color="white"
                        css={{ transform: 'rotate(45deg)' }}
                    />
                </Box>
            </Tooltip>
        </>
    );
};

export default ImgEditorControls;
