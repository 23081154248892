import React from 'react';
import { HStack, Box, Text, Button, ChakraProps } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarSvg from '../assets/CalendarSvg';
import { enUS } from 'date-fns/locale';
registerLocale('enUS', enUS);

interface IDatePicker {
    startDate: Date;
    endDate: Date;
    onStartDateChange: (date: Date) => void;
    onEndDateChange: (date: Date) => void;
}

const DatePickerComponent: React.FC<IDatePicker> = ({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
}) => {
    const datePickerStyles: ChakraProps['css'] = {
        '.react-datepicker-wrapper .react-datepicker__input-container': {
            '> input': {
                width: '67px',
                fontSize: '14px',
                fontWeight: '700',
                marginLeft: '8px',

                '&:focus-visible': {
                    outline: 'unset',
                },
            },
        },

        '.react-datepicker__month-container': {
            padding: '16px',

            '.react-datepicker__header': {
                backgroundColor: 'white',
                borderBottom: 'unset',
                padding: '0',
            },

            '.react-datepicker__month': {
                margin: '0.4rem 0',
            },
        },

        '.react-datepicker__day-names, .react-datepicker__week': {
            fontFamily: 'ApercuPro',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '400',
        },

        '.react-datepicker__day-names': {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '16px',

            '.react-datepicker__day-name': {
                width: '44px',
                height: '32px',
            },
        },

        '.react-datepicker__day': {
            width: 'fit-content',
            height: 'fit-content',
            background: 'unset',

            '&:hover': {
                background: 'unset',
            },
        },

        '.react-datepicker__triangle': {
            display: 'none',
        },

        '.react-datepicker': {
            border: 'unset',
        },

        '.react-datepicker-popper': {
            inset: '4px auto auto -25px !important',
        },

        '.react-datepicker__day--outside-month': {
            visibility: 'hidden',
        },
    };

    return (
        <HStack h="17px">
            <Box fontSize="14px" fontWeight="700" borderRight="1px solid black">
                <Box display="flex" flexDirection="row" css={datePickerStyles}>
                    <CalendarSvg />
                    <DatePicker
                        locale={enUS}
                        dateFormat="dd MMM yy"
                        selected={new Date(startDate.setHours(0, 0, 0, 0))}
                        onChange={(date: Date) => {
                            onStartDateChange(date);
                        }}
                        calendarStartDay={1}
                        placeholderText="Start Date"
                        formatWeekDay={dayName => dayName[0]}
                        renderCustomHeader={({
                            date,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <Box display="flex" justifyContent="space-between">
                                <Text variant="datePickerHeader">
                                    {date.toLocaleString('default', {
                                        month: 'short',
                                        year: 'numeric',
                                    })}
                                </Text>
                                <Box>
                                    <Button
                                        variant="navDatebutton"
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}
                                    >
                                        <ChevronLeftIcon w={7} h={7} />
                                    </Button>
                                    <Button
                                        variant="navDatebutton"
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled}
                                    >
                                        <ChevronRightIcon w={7} h={7} />
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        renderDayContents={(day, date) => {
                            const isSelectedDate =
                                startDate.setHours(0, 0, 0, 0) ===
                                date.setHours(0, 0, 0, 0);
                            return (
                                <Box
                                    w="44px"
                                    h="44px"
                                    display="flex"
                                    borderRadius="50%"
                                    backgroundColor={
                                        isSelectedDate ? 'asuPurple.500' : 'white'
                                    }
                                    color={isSelectedDate ? 'white' : 'black'}
                                    alignItems="center"
                                    alignContent="center"
                                    _hover={{
                                        backgroundColor: 'asuPurple.500',
                                        color: 'white',
                                    }}
                                >
                                    <Text fontSize="16px" lineHeight="20px" m="auto">
                                        {day}
                                    </Text>
                                </Box>
                            );
                        }}
                    />
                </Box>
            </Box>
            <Box fontSize="14px" fontWeight="700">
                <Box display="flex" flexDirection="row" css={datePickerStyles}>
                    <CalendarSvg />
                    <DatePicker
                        locale={enUS}
                        dateFormat="dd MMM yy"
                        selected={new Date(endDate.setHours(0, 0, 0, 0))}
                        onChange={(date: Date) => {
                            onEndDateChange(date);
                        }}
                        calendarStartDay={1}
                        placeholderText="End Date"
                        formatWeekDay={dayName => dayName[0]}
                        renderCustomHeader={({
                            date,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <Box display="flex" justifyContent="space-between">
                                <Text variant="datePickerHeader">
                                    {date.toLocaleString('default', {
                                        month: 'short',
                                        year: 'numeric',
                                    })}
                                </Text>
                                <Box>
                                    <Button
                                        variant="navDatebutton"
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}
                                    >
                                        <ChevronLeftIcon w={7} h={7} />
                                    </Button>
                                    <Button
                                        variant="navDatebutton"
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled}
                                    >
                                        <ChevronRightIcon w={7} h={7} />
                                    </Button>
                                </Box>
                            </Box>
                        )}
                        renderDayContents={(day, date) => {
                            const isSelectedDate =
                                endDate.setHours(0, 0, 0, 0) ===
                                date.setHours(0, 0, 0, 0);
                            return (
                                <Box
                                    w="44px"
                                    h="44px"
                                    display="flex"
                                    borderRadius="50%"
                                    backgroundColor={
                                        isSelectedDate ? 'asuPurple.500' : 'white'
                                    }
                                    color={isSelectedDate ? 'white' : 'black'}
                                    alignItems="center"
                                    alignContent="center"
                                    _hover={{
                                        backgroundColor: 'asuPurple.500',
                                        color: 'white',
                                    }}
                                >
                                    <Text fontSize="16px" lineHeight="20px" m="auto">
                                        {day}
                                    </Text>
                                </Box>
                            );
                        }}
                    />
                </Box>
            </Box>
        </HStack>
    );
};

export default DatePickerComponent;
