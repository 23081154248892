import * as React from 'react';

const AlertMaybeSvg = (props: any) => (
  <svg
    width={props.width || 17}
    height={props.height || 16}
    fill={props.fill || 'none'}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5475 14.3875L8.34365 2.54384L2.13984 14.3875H14.5475ZM15.88 16H0.807348C0.201329 16 -0.188055 15.3565 0.0931418 14.8196L7.62945 0.432146C7.93126 -0.144049 8.75604 -0.144049 9.05786 0.432147L16.5942 14.8196C16.8754 15.3565 16.486 16 15.88 16ZM7.53776 11.1623V5.51851H9.15026V11.1623H7.53776ZM8.34365 13.5812C8.78893 13.5812 9.14991 13.2203 9.14991 12.775C9.14991 12.3297 8.78893 11.9687 8.34365 11.9687C7.89837 11.9687 7.5374 12.3297 7.5374 12.775C7.5374 13.2203 7.89837 13.5812 8.34365 13.5812Z"
      fill={props.fill || '#8F7000'}
    />
  </svg>
);

export default AlertMaybeSvg;
