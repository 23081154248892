import * as React from 'react';

const UserProfileSvg = () => (
    <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 4C3.5 5.93025 5.06975 7.5 7 7.5C8.93025 7.5 10.5 5.93025 10.5 4C10.5 2.07062 8.93025 0.5 7 0.5C5.06975 0.5 3.5 2.07062 3.5 4ZM5.25 4C5.25 3.034 6.03487 2.25 7 2.25C7.96512 2.25 8.75 3.034 8.75 4C8.75 4.96512 7.96512 5.75 7 5.75C6.03487 5.75 5.25 4.96512 5.25 4Z"
            fill="#111111"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 13.625V14.5H14V13.625C14 10.5336 11.1213 8.375 7 8.375C2.87875 8.375 0 10.5336 0 13.625ZM12.1118 12.75H1.88912C2.41237 11.168 4.36888 10.125 7.00088 10.125C9.63288 10.125 11.5885 11.168 12.1118 12.75Z"
            fill="#111111"
        />
    </svg>
);

export default UserProfileSvg;
