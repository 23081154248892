import * as React from 'react';

const CalendarSvg = () => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.3335 3.83337H12.6668V1.83337H11.3335V5.16671H10.6668V3.83337H6.00016V1.83337H4.66683V5.16671H4.00016V3.83337H2.66683C1.9315 3.83337 1.3335 4.43204 1.3335 5.16671V13.8334C1.3335 14.5687 1.9315 15.1667 2.66683 15.1667H13.3335C14.0688 15.1667 14.6668 14.5687 14.6668 13.8334V5.16671C14.6668 4.43204 14.0688 3.83337 13.3335 3.83337ZM13.3315 13.8334H2.66683V6.50004H13.3335L13.3315 13.8334Z"
            fill="#111111"
        />
    </svg>
);

export default CalendarSvg;
