import * as React from 'react';

const DownloadImgSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={24} cy={24} r={24} fill="#111" fillOpacity={0.9} />
        <path
            d="m28.293 21.293 1.414 1.414L24 28.414l-5.707-5.707 1.414-1.414L23 24.586V14h2v10.586l3.293-3.293ZM30 32v-2h2v2c0 1.102-.896 2-2 2H18c-1.104 0-2-.898-2-2v-2h2v2h12Z"
            fill="#F0F0F0"
        />
    </svg>
);

export default DownloadImgSvg;
