import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import AsurionLogoSvg from '../assets/AsurionLogoSvg';
import SmartScanConsoleLogoSvg from '../assets/SmartScanConsoleLogoSvg';
import VerticalCenter from './layouts/VerticalCenter';

const Login: React.FC = () => {
    const handleLogin = useCallback(() => {
        const domain = process.env.REACT_APP_COGNITO_DOMAIN;
        const redirectSignIn =
            window.location.href + process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN;
        const responseType = process.env.REACT_APP_COGNITO_RESPONSE_TYPE;
        const userPoolWebClientId = process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID;

        const url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;

        window.location.replace(url);
    }, []);

    return (
        <VerticalCenter
            innerProps={{ w: '385px', m: '0', borderRadius: '8px' }}
            id={'login-component'}
        >
            <Flex
                alignContent={'flex-end'}
                background={`asuWhite.200`}
                borderRadius={'0'}
                borderTopRightRadius={'8px'}
                borderTopLeftRadius={'8px'}
            >
                <Box w="69px" margin={'29px 10px 24px 34px'}>
                    <AsurionLogoSvg />
                </Box>
                <Divider
                    orientation="vertical"
                    h={'30px'}
                    borderColor={'asuGray.100'}
                    margin={'20px 2px 23px 2px'}
                />
                <Box w="217px" margin={'20px 16px 16px 10px'}>
                    <SmartScanConsoleLogoSvg />
                </Box>
            </Flex>
            <Box
                px={'16px'}
                py={'24px'}
                background={`rgba(249,249,249,0.6)`}
                borderRadius={'0'}
                borderBottomLeftRadius={'8px'}
                borderBottomRightRadius={'8px'}
            >
                <Text fontSize={'24px'} fontWeight={'700'} textAlign={'center'}>
                    Welcome Back
                </Text>
                <Text
                    fontSize={'18px'}
                    fontWeight={'400'}
                    textAlign={'center'}
                    padding={'4px 0 8px 0'}
                >
                    Login using your Asurion SSO credentials
                </Text>
                <Button variant={'solid'} w={'full'} onClick={handleLogin}>
                    Login via SSO
                </Button>
            </Box>
        </VerticalCenter>
    );
};

export default Login;
