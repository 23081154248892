import { Skeleton } from '@chakra-ui/react';
import React, { useState } from 'react';
import VideoPlayer from './VideoPlayer';

interface VideoPlayerSkeletonProps {
    video: any;
    idx: number;
    recordId: string;
    reviewer: string;
    reviewStatus: string;
}

const VideoPlayerSkeleton: React.FC<VideoPlayerSkeletonProps> = ({ video, idx, recordId, reviewer, reviewStatus }) => {
    const [videoIsLoaded, setVideoIsLoaded] = useState(false);

    return (
        <Skeleton
            variant="mediaSkeleton"
            h={videoIsLoaded ? 'fit-content' : '688px'}
            isLoaded={videoIsLoaded}
        >
            <VideoPlayer
                key={`video-comp-${idx}`}
                video={video}
                idx={idx}
                recordId={recordId}
                setVideoIsLoaded={setVideoIsLoaded}
                reviewer={reviewer}
                reviewStatus={reviewStatus}
            />
        </Skeleton>
    );
};

export default VideoPlayerSkeleton;
