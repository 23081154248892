import { Box, Flex, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

const DetailTextWithIcon: React.FC<{
    value?: string;
    icon?: ReactNode;
}> = ({ value, icon }) => {
    return (
        <Flex direction={'row'}>
            {value && <Box marginRight={'10px'}>{icon}</Box>}
            <Box>
                <Text variant="fieldValue">{value || '-'}</Text>
            </Box>
        </Flex>
    );
};

export default DetailTextWithIcon;
