import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Select,
    Spacer,
    Text,
    Textarea,
} from '@chakra-ui/react';
import React, { ReactNode, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppFeatures, SCREEN_STATE } from '../../config/config';
import { isFeatureEnabled } from '../../lib/helpers';
import ConfirmDialog from '../ConfirmDialog';
import MultiSelect from '../MultiSelect';

interface Labels {
    screenTitle: string;
    confirmMessage: any;
    goBackBtn: string;
    submitBtn: string;
    reasonLabel: string;
    noteLabel: string;
    reasonPlaceholder: string;
    notePlaceholder: string;
}

interface Props {
    recordId: string;
    onWorkflowChange: Function;
    onSubmit: Function;
    onClickBack?: Function;
    screenLabels: Labels;
    iconSvg: ReactNode;
    screenState: SCREEN_STATE;
    reasons: any[];
}

const WorkflowForm: React.FC<Props> = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    recordId,
    onWorkflowChange,
    onSubmit,
    onClickBack,
    screenLabels,
    iconSvg,
    screenState,
    reasons,
}) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [workflowData, setWorkflowData] = useState<any>();
    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
    const [errorMessages, setErrorMessages] = useState<any>(null);
    const [, setUpdatedTs] = useState<any>(null);

    const {
        screenTitle,
        confirmMessage,
        goBackBtn,
        submitBtn,
        reasonLabel,
        noteLabel,
        reasonPlaceholder,
        notePlaceholder,
    } = screenLabels;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm();

    const submitFormHandler = values => {
        const data = { reason: selectedReasons, notes: values.notes, state: screenState };

        let messages: any = {};
        let errors = 0;
        if (
            selectedReasons &&
            selectedReasons.length === 0 &&
            screenState !== SCREEN_STATE.APPROVED
        ) {
            messages.reason = 'Please select a reason.';
            errors++;
        } else {
            messages.reason = '';
        }

        setErrorMessages(messages);

        if (errors === 0) {
            setWorkflowData(data);
            setShowConfirm(true);
        }
    };

    const getWorkflowStatus = screenState => {
        switch (screenState) {
            case SCREEN_STATE.REJECTED:
                return 'reject';
                break;
            case SCREEN_STATE.REDO:
                return 'redo';
                break;
            case SCREEN_STATE.ESCALATED:
                return 'escalate';
                break;
        }
    };

    const updateReasons = useCallback(reasons => {
        setSelectedReasons(reasons);
        setUpdatedTs(new Date().getTime());
    }, []);

    const onChangeSingleReason = useCallback(
        e => {
            const { value } = e.target;
            let _reasons = selectedReasons;

            if (value) {
                _reasons[0] = value;
            } else {
                _reasons = [];
            }

            updateReasons(_reasons);
        },
        [selectedReasons, updateReasons]
    );

    const onChangeMultipleReason = useCallback(
        ({ value, checked }) => {
            if (checked) {
                let _reasons = selectedReasons;
                if (_reasons && _reasons.indexOf(value) === -1) {
                    _reasons.push(value);
                    updateReasons(_reasons);
                }
            } else {
                let newReasons: string[] = [];
                selectedReasons.map((reason: string) => {
                    if (reason !== value) {
                        newReasons.push(reason);
                    }
                });
                updateReasons(newReasons);
            }
        },
        [selectedReasons, updateReasons]
    );

    return (
        <>
            {showConfirm && (
                <ConfirmDialog
                    open={showConfirm}
                    content={confirmMessage}
                    onYes={() => {
                        if (!isSubmitting) {
                            onSubmit(screenState, workflowData);
                        }
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                    yesLabel={'Submit'}
                    noLabel={'Cancel'}
                />
            )}
            <form onSubmit={handleSubmit(submitFormHandler)}>
                <FormControl isInvalid={errors && errors.reason ? true : false}>
                    <Box>
                        <Flex direction={'row'} mb={'18px'}>
                            <Box>
                                <Button
                                    variant={'solid'}
                                    backgroundColor={'asuGray.20'}
                                    p={'12px 24px'}
                                    onClick={() => {
                                        updateReasons([]);
                                        onClickBack && onClickBack();
                                        onWorkflowChange(SCREEN_STATE.DEFAULT);
                                    }}
                                >
                                    {goBackBtn}
                                </Button>
                            </Box>
                            <Spacer />
                            <Box>
                                <Flex direction={'row'}>
                                    <Box>{iconSvg}</Box>
                                    <Box p={'5px 10px'}>
                                        <Text fontSize={'24px'} fontWeight={700}>
                                            {screenTitle}
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>
                            <Spacer />
                            <Box>
                                <Button variant={'solid'} type="submit">
                                    {submitBtn}
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                    <Box>
                        {reasonLabel && <Text fontWeight={700}>{reasonLabel}</Text>}
                    </Box>
                    {isFeatureEnabled(AppFeatures.MULTIPLE_REVIEWER_REASON) &&
                        screenState !== SCREEN_STATE.APPROVED ? (
                        <Box>
                            <MultiSelect
                                id={'reviewer-reason'}
                                placeholder={
                                    selectedReasons && selectedReasons.length > 0
                                        ? `${selectedReasons.length} ${getWorkflowStatus(
                                            screenState
                                        )} ${selectedReasons.length === 1
                                            ? 'reason'
                                            : 'reasons'
                                        } selected`
                                        : `Select ${getWorkflowStatus(
                                            screenState
                                        )} reason`
                                }
                                items={reasons}
                                selectedItems={selectedReasons}
                                error={errorMessages?.reason || ''}
                                onSelectItem={onChangeMultipleReason}
                            ></MultiSelect>
                        </Box>
                    ) : (
                        <Box mb={'10px'}>
                            {reasons && reasons.length > 0 && (
                                <Select
                                    placeholder={reasonPlaceholder}
                                    id={'reason'}
                                    onChange={onChangeSingleReason}
                                >
                                    {reasons.map((reason, index) => {
                                        const { value, label } = reason;

                                        return (
                                            <option
                                                value={value}
                                                key={`reason-option-${screenState}-${value}-${index}`}
                                            >
                                                {label}
                                            </option>
                                        );
                                    })}
                                </Select>
                            )}
                            {errorMessages && errorMessages.reason && (
                                <Box>
                                    <Text color={'red'}>{errorMessages.reason}</Text>
                                </Box>
                            )}
                        </Box>
                    )}

                    <Box>
                        <Text fontWeight={700}>{noteLabel}</Text>
                    </Box>
                    <Box>
                        <Textarea
                            id="notes"
                            {...register('notes')}
                            w={'full'}
                            h={'190px'}
                            resize={'none'}
                            placeholder={notePlaceholder}
                        />
                    </Box>
                    <Box>
                        {errors.notes && errors.notes.message && (
                            <FormErrorMessage>
                                {errors.notes.message.toString()}
                            </FormErrorMessage>
                        )}
                    </Box>
                </FormControl>
            </form>
        </>
    );
};

export default WorkflowForm;
