import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Spinner, Button } from '@chakra-ui/react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { ICrackDetectionPhoto, IRecordPhoto } from '../../config/interface';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../lib/analytics';
import { getAiReviewPhotos } from '../../lib/helpers';
import {
    fetchCrackDetectionData,
    fetchEnrollmentPhotos,
} from '../../services/apiService';
import BorderedBox from '../layouts/BorderedBox';
import VerticalCenter from '../layouts/VerticalCenter';
import PhotoViewerSkeleton from './media-gallery/PhotoViewerSkeleton';

interface ReviewPhotosProps {
    recordId: string;
    detectedDevice: string;
    reviewer: string;
    reviewStatus: string;
    onPhotosFetched: Function;
}

const ReviewPhotos: React.FC<ReviewPhotosProps> = ({
    recordId,
    detectedDevice,
    reviewer,
    reviewStatus,
    onPhotosFetched,
}) => {
    const [scrollIndex, setScrollIndex] = useState(0);
    const [aiPhotoMapping, setAiPhotoMapping] = useState<{
        [key: string]: ICrackDetectionPhoto;
    }>({});
    const [originalPhotos, setOriginalPhotos] = useState<IRecordPhoto[]>([]);
    const { addEvent } = useAnalytics();

    // GTM
    const addGtmEvents = (origPhotos, aiPhotoMapping) => {
        origPhotos.forEach(photo => {
            const { imageType } = photo;
            let otherGtmData: any = {};

            // Add AI details
            if (photo.dataPoints) {
                otherGtmData['AIScreenDetected'] = photo?.dataPoints?.detectedScreen;
                otherGtmData['AISharpnessScore'] = photo?.dataPoints?.sharpnessScore;
                otherGtmData['AIFingersDetected'] = photo?.dataPoints?.fingersDetected;
                otherGtmData['AIScreenDetectedConfidence'] =
                    photo?.dataPoints?.confidenceScore;
            }

            if (imageType === 'imei') {
                addEvent(recordId, ANALYTICS_EVENTS.AI_IMEI, otherGtmData);
            }

            if (imageType === 'front' || imageType === 'back') {
                // Add Crack Detection details
                const aiPhoto = aiPhotoMapping[photo.fileId];

                // Trigger GTM event
                if (imageType === 'front') {
                    if (aiPhoto) {
                        otherGtmData['AIGradeFront'] = aiPhoto?.dataPoints?.grade;
                        otherGtmData['AINumberOfCracksFront'] =
                            aiPhoto?.dataPoints?.crackCount;
                    }
                    addEvent(recordId, ANALYTICS_EVENTS.AI_FRONT, otherGtmData);
                } else {
                    if (aiPhoto) {
                        otherGtmData['AIGradeBack'] = aiPhoto?.dataPoints?.grade;
                        otherGtmData['AINumberOfCracksBack'] =
                            aiPhoto?.dataPoints?.crackCount;
                    }
                    addEvent(recordId, ANALYTICS_EVENTS.AI_BACK, otherGtmData);
                }
            }
        });
    };

    // API Calls
    const useFetchCrackDetection: () => UseQueryResult<ICrackDetectionPhoto[]> = () => {
        return useQuery(['crack_detection', recordId], () =>
            fetchCrackDetectionData(recordId)
        );
    };

    const { data: crackDetectionDetails, isFetching: isFetchingCrackDetection } =
        useFetchCrackDetection();

    const useFetchPhotos: () => UseQueryResult<IRecordPhoto[]> = () => {
        return useQuery(['photos', recordId], () => fetchEnrollmentPhotos(recordId));
    };

    const {
        data: enrollmentPhotos,
        isFetching: isFetchingEnrollmentPhotos,
        refetch: refetchReviewPhotos,
    } = useFetchPhotos();

    // Data processing
    useEffect(() => {
        if (enrollmentPhotos && enrollmentPhotos.length > 0) {
            setOriginalPhotos(enrollmentPhotos);
            onPhotosFetched(enrollmentPhotos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrollmentPhotos]);

    useEffect(() => {
        if (crackDetectionDetails && crackDetectionDetails.length > 0) {
            let resultData: { [key: string]: ICrackDetectionPhoto } =
                getAiReviewPhotos(crackDetectionDetails);
            setAiPhotoMapping(resultData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crackDetectionDetails]);

    useEffect(() => {
        if (
            originalPhotos &&
            originalPhotos.length > 0 &&
            aiPhotoMapping &&
            Object.keys(aiPhotoMapping).length > 0
        ) {
            addGtmEvents(originalPhotos, aiPhotoMapping);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalPhotos, aiPhotoMapping]);

    return (
        <BorderedBox styleProps={{ p: 0, display: 'flex', flexDirection: 'row' }}>
            <Flex
                id={`photos-scroll-container-${recordId}`}
                w="calc(100% - 200px)"
                minHeight="200px"
                direction="row"
                m="24px"
                overscrollBehaviorX="contain"
                scrollSnapType="x mandatory"
                overflowX="scroll"
                css={{
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                {(isFetchingEnrollmentPhotos || isFetchingCrackDetection) && (
                    <VerticalCenter
                        innerProps={{ w: 'auto', m: 'auto', borderRadius: '8px' }}
                        h={'100%'}
                        m={'auto'}
                    >
                        <Spinner variant={'pageLoader'} />
                    </VerticalCenter>
                )}
                {!isFetchingEnrollmentPhotos &&
                    !isFetchingCrackDetection &&
                    originalPhotos &&
                    originalPhotos.length !== 0 &&
                    originalPhotos.map((photo, idx) => (
                        <PhotoViewerSkeleton
                            key={`photo-skeleton-${photo.fileId}-${idx}`}
                            photo={photo}
                            idx={idx}
                            recordId={recordId}
                            aiPhoto={
                                aiPhotoMapping ? aiPhotoMapping[photo.fileId] : undefined
                            }
                            detectedDevice={detectedDevice}
                            refetchReviewPhotos={refetchReviewPhotos}
                            reviewer={reviewer}
                            reviewStatus={reviewStatus}
                        />
                    ))}
            </Flex>
            {!isFetchingEnrollmentPhotos && originalPhotos && originalPhotos.length > 4 && (
                <Flex alignItems="center">
                    <Button
                        variant="scrollSnapButton"
                        onClick={() => {
                            if (scrollIndex >= 1) {
                                document
                                    .getElementById(`photos-scroll-container-${recordId}`)
                                    ?.scrollTo({
                                        left: (scrollIndex - 1) * 360,
                                        behavior: 'smooth',
                                    });
                                setScrollIndex(scrollIndex - 1);
                            }
                        }}
                    >
                        <ChevronLeftIcon boxSize={10} />
                    </Button>
                    <Button
                        variant="scrollSnapButton"
                        onClick={() => {
                            if (scrollIndex < originalPhotos.length - 3) {
                                document
                                    .getElementById(`photos-scroll-container-${recordId}`)
                                    ?.scrollTo({
                                        left: (scrollIndex + 1) * 360,
                                        behavior: 'smooth',
                                    });
                                setScrollIndex(scrollIndex + 1);
                            }
                        }}
                    >
                        <ChevronRightIcon boxSize={10} />
                    </Button>
                </Flex>
            )}
        </BorderedBox>
    );
};

export default ReviewPhotos;
