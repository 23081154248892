import React, { FC, useState, useCallback, useEffect } from 'react';
import {
    Button,
    Center,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
} from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';

import FullWidth from '../components/FullWidth';
import VerticalCenter from '../components/layouts/VerticalCenter';
import { AppConfig, AppRoutes } from '../config/config';
import { account, activeRegion, apiToken, ssoSession } from '../entities/session';
import {
    twilioWorkerToken,
    twilioWorkspaceToken,
    workerActivityList,
    workerStatus,
} from '../entities/twilio';
import useAmplify from '../hooks/useAmplify';
import { useQuery } from '@tanstack/react-query';
import { fetchTwilioToken, fetchUserInfo } from '../services/apiService';
import { parseErrorResponse } from '../lib/helpers';

const AuthPage: FC = () => {
    const amplify = useAmplify();

    const accountData: any = account.use();
    const ssoSessionData: any = ssoSession.use();
    const sessionRegion = activeRegion.use();

    const [checkingSession, setCheckingSession] = useState(true);
    const [currentSession, setCurrentSession] = useState<any>(null);
    const [currentAccount, setCurrentAccount] = useState<any>(null);
    const [region] = useState<any>(sessionRegion || AppConfig.defaultRegion);
    const [workerSid, setWorkerSid] = useState<any>(accountData?.sid || null);
    const [workspaceToken, setWorkspaceToken] = useState<any>(null);
    const [workerToken, setWorkerToken] = useState<any>(null);
    const [apiSecurityToken, setApiSecurityToken] = useState<any>(null);
    const [authorized, setAuthorized] = useState<any>(null);

    function useUser(email, region, data): any {
        return useQuery(
            ['userinfo', email, region, data],
            () => {
                return fetchUserInfo({ email, region });
            },
            {
                enabled: email && region && !data && authorized === null ? true : false,
            }
        );
    }

    function useFetchTwilioToken(region, workerSid): any {
        return useQuery(
            ['twilio', region, workerSid],
            () => {
                return fetchTwilioToken({ region, workerSid });
            },
            {
                enabled: region && workerSid ? true : false,
            }
        );
    }

    const {
        data: userData,
        error: userError,
        isFetching: isUserFetching,
    } = useUser(ssoSessionData?.email, region, accountData);

    if (!isUserFetching && !userError) {
        if (userData) {
            account.set(userData);
            activeRegion.set(region);
            setWorkerSid(userData?.sid);
            setCurrentAccount(userData);
        }
    }

    if (!isUserFetching && userError) {
        const { statusCode } = parseErrorResponse(userError);

        if (statusCode === 404 && authorized === null) {
            // no twilio worker for this user
            setAuthorized(false);
        }
    }

    const {
        data: twilioData,
        error: twilioError,
        isFetching: isTwilioFetching,
    } = useFetchTwilioToken(region, workerSid);

    if (
        !isTwilioFetching &&
        !twilioError &&
        !workerToken &&
        !workspaceToken &&
        !apiSecurityToken
    ) {
        if (twilioData) {
            if (twilioData.workerToken) {
                twilioWorkerToken.set(twilioData.workerToken);
                setWorkerToken(twilioData.workerToken);
            }
            if (twilioData.workspaceToken) {
                twilioWorkspaceToken.set(twilioData.workspaceToken);
                setWorkspaceToken(twilioData.workspaceToken);
            }
            if (twilioData.apiToken) {
                apiToken.set(twilioData?.apiToken);
                setApiSecurityToken(twilioData?.apiToken);
            }
        }
    }

    const doLogout = () => {
        ssoSession.set(null);
        account.set(null);
        twilioWorkspaceToken.set(null);
        twilioWorkerToken.set(null);
        workerStatus.set(null);
        workerActivityList.set(null);
        apiToken.set(null);

        window.location.href = '/';
    };

    const getCurrentSession = useCallback(async () => {
        setCheckingSession(true);
        const authenticated = await amplify.isAuthenticated();

        if (authenticated) {
            const session = await amplify.getCurrentSession();
            if (session && session.idToken && session.idToken.payload) {
                const payload = session.idToken.payload;
                const _currentSession: any = {
                    email: payload?.email,
                    username: payload['cognito:username'] || '',
                    authenticated: authenticated,
                };
                setCurrentSession(_currentSession);
                ssoSession.set(_currentSession);
                activeRegion.set(region);
            } else {
                setCurrentSession(null);
            }
        }
        setCheckingSession(false);
    }, [amplify, region]);

    useEffect(() => {
        amplify.configure({
            domainApp: global.window.location.origin,
        });

        if (!currentSession) {
            getCurrentSession();
        }
    }, [amplify, getCurrentSession, currentSession]);

    useEffect(() => {
        if (!currentSession && ssoSessionData) {
            setCurrentSession(ssoSessionData);
        }

        if (!currentAccount && accountData) {
            setCurrentAccount(accountData);
        }
    }, [currentSession, currentAccount, ssoSessionData, accountData]);

    return (
        <>
            {authorized === false && (
                <Modal
                    isOpen={true}
                    onClose={() => {
                        doLogout();
                    }}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Center>No Twilio Worker</Center>
                        </ModalHeader>
                        <ModalBody>
                            <Center>
                                This user has no associated Twilio Worker yet.
                            </Center>
                        </ModalBody>
                        <Center>
                            <ModalFooter>
                                <Button
                                    onClick={() => {
                                        doLogout();
                                    }}
                                    variant="solid"
                                >
                                    Go Back To Login
                                </Button>
                            </ModalFooter>
                        </Center>
                    </ModalContent>
                </Modal>
            )}
            {workerToken &&
                workspaceToken &&
                currentSession &&
                currentAccount &&
                apiSecurityToken &&
                checkingSession === false && (
                    <Navigate to={AppRoutes.SEARCH} replace></Navigate>
                )}
            {currentSession === null &&
                currentAccount === null &&
                checkingSession === false && <Navigate to={AppRoutes.INDEX} replace />}

            {(checkingSession === true || isUserFetching || isTwilioFetching) && (
                <FullWidth p={4}>
                    <VerticalCenter
                        innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                    >
                        <Spinner variant={'pageLoader'} />
                    </VerticalCenter>
                </FullWidth>
            )}
        </>
    );
};

export default AuthPage;
