import * as React from 'react';

const ForReviewMediaSvg = () => (
    <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.810547" y="0.189453" width="50" height="50" rx="10" fill="#6700B8" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8105 39.3809C33.6482 39.3809 40.002 33.0272 40.002 25.1895C40.002 17.3518 33.6482 10.998 25.8105 10.998C17.9729 10.998 11.6191 17.3518 11.6191 25.1895C11.6191 33.0272 17.9729 39.3809 25.8105 39.3809Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.8111 26.4229C28.7076 26.4229 31.0557 24.0748 31.0557 21.1782C31.0557 18.2817 28.7076 15.9336 25.8111 15.9336C22.9145 15.9336 20.5664 18.2817 20.5664 21.1782C20.5664 24.0748 22.9145 26.4229 25.8111 26.4229Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.6064 33.2116C32.1808 30.1975 29.1453 28.2754 25.811 28.2754C22.4768 28.2754 19.4413 30.1975 18.0156 33.2116"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ForReviewMediaSvg;
