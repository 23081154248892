import { Box, Flex, IconButton, Spinner } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import FmipRefreshSvg from '../../../assets/FmipRefreshSvg';
import { fetchFmipStatus } from '../../../services/apiService';

const DetailFmipStatusCheck: React.FC<{ fmip: boolean; details: any }> = ({
    fmip,
    details,
}) => {
    const { imei } = details;

    const [status, setStatus] = useState<any>(fmip);

    const { mutate: refetchStatus, isLoading } = useMutation(fetchFmipStatus, {
        onSuccess: data => {
            const { fmip } = data;
            setStatus(fmip);
        },
    });

    return (
        <Flex direction={'row'}>
            <Box>{status ? 'On' : 'Off'}</Box>
            <Box w={'28px'} h={'20px'} pl={'8px'}>
                {isLoading && (
                    <Spinner variant={'componentLoader'} w={'28px'} h={'28px'} />
                )}
                {!isLoading && (
                    <IconButton
                        aria-label="fmip refresh"
                        icon={<FmipRefreshSvg />}
                        variant="transparent"
                        w={'28px'}
                        h={'20px'}
                        minH={'unset'}
                        minW={'unset'}
                        onClick={() => {
                            refetchStatus({ imei });
                        }}
                    />
                )}
            </Box>
        </Flex>
    );
};

export default DetailFmipStatusCheck;
