import { Flex } from '@chakra-ui/react';
import React from 'react';
import ForReviewMediaSvg from '../../../assets/photo-gallery/ForReviewMediaSvg';
import ImgEditorControls from './ImgEditorControls';
import PhotoFloatingLayer from './PhotoFloatingLayer';

interface ImgEditorComponentProps {
    recordId: string;
    idx: number;
    setImgEditorMode: any;
    handleZoomIn: any;
    handleZoomOut: any;
    handleRotate: any;
    handleFlip: any;
    handleDownload: any;
    myCanvas: any;
}

const ImgEditorComponent: React.FC<ImgEditorComponentProps> = ({
    recordId,
    idx,
    setImgEditorMode,
    handleZoomIn,
    handleZoomOut,
    handleRotate,
    handleFlip,
    handleDownload,
    myCanvas
}) => {
    return (
        <>
            {/* Floating layer */}
            <PhotoFloatingLayer>
                <ForReviewMediaSvg />
                <ImgEditorControls
                    setImgEditorMode={setImgEditorMode}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    handleRotate={handleRotate}
                    handleFlip={handleFlip}
                    handleDownload={handleDownload}
                />
            </PhotoFloatingLayer>

            {/* Displayed layer */}
            <Flex
                w="360px"
                height="640px"
                borderRadius="8px"
                border="1px solid"
                borderColor="#A839F3"
                overflow="hidden"
            >
                <Flex>
                    <canvas
                        id={`canvas-edit-${recordId}-${idx}`}
                        ref={myCanvas}
                        width="360px"
                        height="640px"
                    />
                </Flex>
            </Flex>
        </>
    );
};

export default ImgEditorComponent;
