import * as React from 'react';

const EscalateScreenSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={24} cy={24} r={24} fill="#A839F3" />
        <path
            d="m35.52 16.844-6.329-6.284-6.329 6.284 1.901 1.888 3.084-3.062v11.776l-11.41-6.798a1.352 1.352 0 0 0-1.354-.016 1.33 1.33 0 0 0-.683 1.161V36.48h2.69V24.15l11.409 6.799c.415.248.933.255 1.354.017.423-.238.683-.682.683-1.163V15.67l3.083 3.062 1.901-1.888Z"
            fill="#fff"
        />
    </svg>
);

export default EscalateScreenSvg;
