import * as React from 'react';

const RedoScreenSvg = props => (
    <svg width={49} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={24.5} cy={24} r={24} fill="#F1822A" />
        <path
            d="M34.46 11.078v7.28h-7.28"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.46 18.36c-3.906-5.374-11.41-6.601-16.825-2.753a11.969 11.969 0 0 0-.115 19.39"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default RedoScreenSvg;
