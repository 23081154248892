import { entity, persistence } from 'simpler-state';

const prevPageTokenKeyName = 'prevPageToken';

function getPrevPageToken(): any {
    const window = global?.window;
    return window && window.localStorage && window.localStorage[prevPageTokenKeyName]
        ? window.localStorage[prevPageTokenKeyName]
        : null;
}

export const prevPageTokenStore = entity(getPrevPageToken(), [
    persistence(prevPageTokenKeyName, { storage: 'local' }),
]);

// used by toast to show messages
export const updatedEnrollment: any = entity(null, [
    persistence('updatedEnrollment', { storage: 'local' }),
]);

export const latestUploadedSpf: any = entity(null, [
    persistence('latestUploadedSpf', { storage: 'local' }),
]);
