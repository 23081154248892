import { useCallback } from 'react';

import analytics, { ANALYTICS_EVENTS } from '../lib/analytics';

const useAnalytics = () => {
    const init = useCallback(() => analytics.init(), []);
    const addEvent = useCallback(
        (recordId, event: ANALYTICS_EVENTS, data: Record<string, any> = {}) => {
            analytics.event(event, {
                smartscanId: recordId,
                system: 'Console',
                partner: 'Singtel',
                program: 'MobileSwop Unlimited Premium Family',
                ...data,
            });
        },
        []
    );

    return { addEvent, init };
};

export default useAnalytics;
