import * as React from 'react';

const CircleCheckSvg = props => (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={10} cy={10.5} r={10} fill="#00B574" />
        <path
            d="m14.8 5.9-6.6 9.2-2.6-2.6"
            stroke="#fff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CircleCheckSvg;
