import { Skeleton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ICrackDetectionPhoto, IRecordPhoto } from '../../../config/interface';
import PhotoViewer from './PhotoViewer';

interface PhotoViewerSkeletonProps {
    photo: IRecordPhoto;
    idx: number;
    recordId: string;
    aiPhoto?: ICrackDetectionPhoto;
    detectedDevice: string;
    refetchReviewPhotos: any;
    reviewer: string;
    reviewStatus: string;
}

const PhotoViewerSkeleton: React.FC<PhotoViewerSkeletonProps> = ({
    photo,
    idx,
    recordId,
    aiPhoto,
    detectedDevice,
    refetchReviewPhotos,
    reviewer,
    reviewStatus,
}) => {
    const [imgIsLoaded, setImgIsLoaded] = useState(false);

    return (
        <Skeleton
            variant="mediaSkeleton"
            h={imgIsLoaded ? 'fit-content' : '766px'}
            isLoaded={imgIsLoaded}
        >
            <PhotoViewer
                key={`photo-comp-${idx}`}
                photo={photo}
                idx={idx}
                setImgIsLoaded={setImgIsLoaded}
                recordId={recordId}
                aiPhoto={aiPhoto}
                detectedDevice={detectedDevice}
                refetchReviewPhotos={refetchReviewPhotos}
                reviewer={reviewer}
                reviewStatus={reviewStatus}
            />
        </Skeleton>
    );
};

export default PhotoViewerSkeleton;
