export const getReviewNotes = () => {
    return [
        {
            id: '98997193043',
            reviewer: 'ChristineJoy',
            commentDate: '2022-09-29T11:22:00Z',
            comment: 'Did not flip the phone to show back',
        },
        {
            id: '98997193042',
            reviewer: 'ChristineJoy',
            commentDate: '2022-09-27T09:30:00Z',
            comment: 'Incomplete video',
        },
        {
            id: '98997193041',
            reviewer: 'ChristineJoy',
            commentDate: '2022-09-25T10:02:00Z',
            comment: 'Blurry images',
        },
    ];
};
