import * as React from 'react';

const TagSvg = () => (
    <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.3335 14.1667C8.16283 14.1667 7.99216 14.1014 7.86216 13.9714L0.528829 6.63804C0.403496 6.51337 0.333496 6.34404 0.333496 6.16671V1.50004C0.333496 1.13137 0.631496 0.833374 1.00016 0.833374H5.66683C5.84416 0.833374 6.0135 0.903374 6.13816 1.02871L13.4715 8.36204C13.7322 8.62271 13.7322 9.04404 13.4715 9.30471L8.80483 13.9714C8.67483 14.1014 8.50416 14.1667 8.3335 14.1667ZM1.66683 5.89071L8.3335 12.5574L12.0575 8.83337L5.39083 2.16671H1.66683V5.89071Z"
            fill="#111111"
        />
        <path
            d="M3.66683 5.50004C4.40321 5.50004 5.00016 4.90309 5.00016 4.16671C5.00016 3.43033 4.40321 2.83337 3.66683 2.83337C2.93045 2.83337 2.3335 3.43033 2.3335 4.16671C2.3335 4.90309 2.93045 5.50004 3.66683 5.50004Z"
            fill="#111111"
        />
    </svg>
);

export default TagSvg;
