import { Box, Flex, Text, Image as ChakraImg, Switch } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import ApprovedMediaSvg from '../../../assets/photo-gallery/ApprovedMediaSvg';
import ForReviewMediaSvg from '../../../assets/photo-gallery/ForReviewMediaSvg';
import ImageEditSvg from '../../../assets/photo-gallery/ImageEditSvg';
import RejectedMediaSvg from '../../../assets/photo-gallery/RejectedMediaSvg';
import { AppScreen } from '../../../config/config';
import AIReviewDisplay from './AIReviewDisplay';
import { uploadFile } from '../../../services/apiService';
import { ICrackDetectionPhoto, IRecordPhoto } from '../../../config/interface';
import { account } from '../../../entities/session';
import ScreenshotViewer from './ScreenshotViewer';
import PhotoFloatingLayer from './PhotoFloatingLayer';
import ImgEditorComponent from './ImgEditorComponent';

interface PhotoViewerProps {
    photo: IRecordPhoto;
    idx: number;
    setImgIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    recordId: string;
    aiPhoto?: ICrackDetectionPhoto;
    detectedDevice: string;
    refetchReviewPhotos: any;
    reviewer: string;
    reviewStatus: string;
}

const PhotoViewer: React.FC<PhotoViewerProps> = ({
    photo,
    idx,
    setImgIsLoaded,
    recordId,
    aiPhoto,
    detectedDevice,
    refetchReviewPhotos,
    reviewer,
    reviewStatus,
}) => {
    const accountInfo: any = account.use();

    // AI states
    const [isReviewed, setIsReviewed] = useState(true);
    let submissionPass =
        photo.source === 'client' &&
        photo.dataPoints.confidenceScore >= photo.dataPoints.confidenceThreshold &&
        photo.dataPoints.sharpnessScore >= photo.dataPoints.sharpnessThreshold;

    // Check AI photo only for FRONT and BACK image types
    if (photo.imageType === 'front' || photo.imageType === 'back') {
        if (aiPhoto !== undefined) {
            const aiGradePass = aiPhoto.dataPoints.grade === 'A';
            submissionPass = submissionPass && aiGradePass;
        } else {
            submissionPass = false;
        }
    }

    // Image Editor states
    const [isImgEditorMode, setImgEditorMode] = useState(false);
    const myCanvas = useRef<HTMLCanvasElement>(null);
    const [zoomRatio, setZoomRatio] = useState(1.0);
    const [canvas, setCanvas] = useState<any>();
    const zoomSize = 0.1;

    useEffect(() => {
        if (isImgEditorMode && myCanvas) {
            const fCanvas = new fabric.Canvas(myCanvas.current);
            fabric.Image.fromURL(
                photo.url,
                img => {
                    // NOTE: to preserve orig img dimensions
                    const scaleX = fCanvas.getWidth() / img.width;
                    const scaleY = fCanvas.getHeight() / img.height;
                    const scaleFactor = Math.min(scaleX, scaleY);

                    const sImg = img.set({
                        originX: 'middle',
                        originY: 'middle',
                        selectable: true,
                        scaleX: scaleFactor,
                        scaleY: scaleFactor,
                    });
                    fCanvas.add(sImg);
                    fCanvas.centerObject(sImg);
                    fCanvas.renderAll();
                },
                {
                    crossOrigin: 'anonymous',
                }
            );

            setCanvas(fCanvas);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isImgEditorMode]);

    useEffect(() => {
        if (canvas) {
            canvas.zoomToPoint(
                new fabric.Point(canvas.width / 2, canvas.height / 2),
                zoomRatio
            );
        }
    }, [zoomRatio, canvas]);

    const handleRotate = () => {
        const angle = canvas.item(0).angle;
        canvas.item(0).angle = angle + 90;
        canvas.renderAll();
    };

    const handleZoomIn = () => {
        setZoomRatio(zoomRatio + zoomSize);
    };

    const handleZoomOut = () => {
        setZoomRatio(zoomRatio - zoomSize);
    };

    const handleFlip = () => {
        if (canvas) {
            const img = canvas.item(0);
            if (img) {
                img.set('flipX', !img.flipX);
                canvas.renderAll();
            }
        }
    };

    const handleDownload = async () => {
        if (canvas) {
            const imgUrl = canvas.toDataURL();
            const img = await fetch(imgUrl);
            const blob = await img.blob();
            const arrBuffer = await blob.arrayBuffer();

            const result = await uploadFile({
                file: arrBuffer,
                recordId,
                uploadType: 'image',
                subType: 'screenshot',
                associatedFileId: photo.fileId,
            });

            if (result.success) {
                refetchReviewPhotos();
            }
            if (result.error) {
                console.info(result.error);
            }
        }
    };

    const handleImgLoadedData = () => {
        setImgIsLoaded(true);
    };

    useEffect(() => {
        /**
        * firsImgToLoad - once this image mounts in document, skeleton disappears
        * For IMEI, Front, Back images - show AI photo
        * For Screenshots - show original photo 
        */
        let firsImgToLoad = `photo-el-${recordId}-${idx}`;
        if (aiPhoto) {
            firsImgToLoad = `ai-photo-el-${recordId}-${idx}`;
        }

        const curImg = document.getElementById(firsImgToLoad);
        if (curImg) {
            const castedImg = curImg as HTMLIFrameElement;
            castedImg.addEventListener('load', handleImgLoadedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            key={`photo-${photo.imageType}-${idx}`}
            w={AppScreen.reviewPage.mediaWidth}
            alignItems="center"
            marginRight="22px"
            scrollSnapAlign="start"
        >
            {photo.source === 'expert' ? (
                <ScreenshotViewer
                    photo={photo}
                    recordId={recordId}
                    idx={idx}
                />
            ) : (
                <>
                    {isImgEditorMode ? (
                        <ImgEditorComponent
                            recordId={recordId}
                            idx={idx}
                            setImgEditorMode={setImgEditorMode}
                            handleZoomIn={handleZoomIn}
                            handleZoomOut={handleZoomOut}
                            handleRotate={handleRotate}
                            handleFlip={handleFlip}
                            handleDownload={handleDownload}
                            myCanvas={myCanvas}
                        />
                    ) : (
                        <>
                            {/* Floating layer */}
                            <PhotoFloatingLayer>
                                {!isReviewed ? (
                                    <ForReviewMediaSvg />
                                ) : submissionPass ? (
                                    <ApprovedMediaSvg />
                                ) : (
                                    <RejectedMediaSvg />
                                )}
                                {reviewer === accountInfo.name &&
                                    reviewStatus !== 'rejected' &&
                                    reviewStatus !== 'approved' &&
                                    !isImgEditorMode &&
                                    !isReviewed && (
                                        <Box
                                            as="button"
                                            position="absolute"
                                            bottom="12px"
                                            right="12px"
                                            onClick={() => setImgEditorMode(true)}
                                        >
                                            <ImageEditSvg />
                                        </Box>
                                    )}
                            </PhotoFloatingLayer>

                            {/* Displayed layer */}
                            <Flex
                                w="360px"
                                height="640px"
                                borderRadius="8px"
                                border="1px solid"
                                borderColor={
                                    !isReviewed
                                        ? '#A839F3'
                                        : submissionPass
                                            ? '#37E7A7'
                                            : '#DB1B1B'
                                }
                                overflow="hidden"
                            >
                                {(isReviewed && aiPhoto !== undefined) ? (
                                    <ChakraImg
                                        id={`ai-photo-el-${recordId}-${idx}`}
                                        src={aiPhoto.url || ''}
                                        alt={photo.imageType}
                                        h="640px"
                                        maxW={AppScreen.reviewPage.mediaWidth}
                                    />
                                ) : (
                                    <ChakraImg
                                        id={`photo-el-${recordId}-${idx}`}
                                        src={photo.url}
                                        alt={photo.imageType}
                                        h="640px"
                                        maxW={AppScreen.reviewPage.mediaWidth}
                                    />
                                )}
                            </Flex>
                        </>
                    )}

                    {/* Photo details */}
                    <Flex
                        direction="row"
                        alignItems="center"
                        alignContent="center"
                        m="10px auto"
                        w="fit-content"
                    >
                        <Text
                            fontSize="24px"
                            fontWeight="700"
                            textAlign="center"
                            textTransform={
                                photo.imageType === 'imei' ? 'uppercase' : 'capitalize'
                            }
                        >
                            {photo.imageType || '-'}
                        </Text>
                        <Switch
                            size="lg"
                            colorScheme="purple"
                            h="30px"
                            ml="10px"
                            isChecked={isReviewed}
                            onChange={() => setIsReviewed(!isReviewed)}
                            disabled={isImgEditorMode}
                        />
                        <Text fontSize="24px" ml="10px">
                            {isReviewed ? 'AI Reviewed' : 'Original'}
                        </Text>
                    </Flex>

                    {isReviewed && (
                        <AIReviewDisplay
                            photo={photo}
                            aiPhoto={aiPhoto}
                            detectedDevice={detectedDevice}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default PhotoViewer;
