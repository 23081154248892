import { Auth, Amplify } from 'aws-amplify';

import { getAmplifyConfigs } from '../config/config';

type UseAmplifyReturnValue = {
    configure: (param: any) => void;
    getCurrentCredentials: () => any;
    isAuthenticated: () => any;
    getCurrentSession: () => any;
    logout: () => any;
};

const useAmplify = (): UseAmplifyReturnValue => {
    const configure = (param: any): any => {
        const { domainApp } = param;
        const amplifyConfig = getAmplifyConfigs(domainApp);

        Amplify.configure(amplifyConfig);
    };

    const isAuthenticated = async () => {
        const cred = await Auth.currentCredentials();
        return cred?.authenticated || false;
    };

    const getCurrentSession = async () => {
        const session = await Auth.currentSession();
        return session;
    };

    const getCurrentCredentials = async () => {
        const curCred = await Auth.currentCredentials();
        return curCred;
    };

    const logout = async () => {
        return await Auth.signOut();
    };

    return {
        configure,
        getCurrentCredentials,
        logout,
        isAuthenticated,
        getCurrentSession,
    };
};

export default useAmplify;
