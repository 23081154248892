import React from 'react';
import { STATUS } from '../../../config/config';
import StatusChip from '../../Table/StatusChip';

const DetailStatusChip: React.FC<{
    value?: string;
}> = ({ value }) => {
    const status = value ? value.toUpperCase() : STATUS.UNDEFINED;
    return <StatusChip status={STATUS[status]} fontSize={'12px'} />;
};

export default DetailStatusChip;
