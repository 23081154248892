import * as React from 'react';

const RotateImgSvg = props => (
    <svg
        width={props.width || 48}
        height={props.height || 48}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={24} cy={24} r={24} fill="#111" fillOpacity={0.9} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 26c0 1.103-.897 2-2 2h-6c-1.103 0-2-.897-2-2V16c0-1.102.897-2 2-2h6c1.103 0 2 .898 2 2v10Zm-2-10h-6v10h6.001L21 16Z"
            fill="#fff"
        />
        <path
            d="M30 19.228v.358l-1.293-1.292-1.414 1.412L31 23.414l3.707-3.708-1.414-1.412L32 19.586v-.358C32 15.954 30.131 14 27 14h-1v2h1c.742 0 3 0 3 3.228ZM31 25h-6v2h6v5H17v-2h-2v2c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5c0-1.102-.897-2-2-2Z"
            fill="#fff"
        />
    </svg>
);

export default RotateImgSvg;
