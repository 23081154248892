import * as React from 'react';

const LogoutSvg = props => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        fill={props.fill || '"none"'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15Z"
            fill="#6EFAC3"
        />
        <path
            d="M15.692 20.75H9.923a.633.633 0 0 1-.641-.625V8.875c0-.345.287-.625.641-.625h5.77c.354 0 .64-.28.64-.625a.633.633 0 0 0-.64-.625h-5.77C8.863 7 8 7.841 8 8.875v11.25C8 21.159 8.863 22 9.923 22h5.77c.354 0 .64-.28.64-.625a.633.633 0 0 0-.64-.625Z"
            fill="#000"
        />
        <path
            d="m22.815 13.617-3.781-3.929a.6.6 0 0 0-.88.007.679.679 0 0 0 .006.926l2.703 2.807h-7.241c-.344 0-.622.293-.622.655 0 .362.278.655.622.655h7.24l-2.701 2.808a.68.68 0 0 0-.007.926.603.603 0 0 0 .88.007l3.78-3.93a.674.674 0 0 0 .186-.466.672.672 0 0 0-.185-.466Z"
            fill="#000"
        />
    </svg>
);

export default LogoutSvg;
