import { Link } from '@chakra-ui/react';
import React from 'react';
import { IEnrollmentDetails } from '../../config/interface';
import Details from './Details';
import DetailEmail from './fields/DetailEmail';
import DetailPhone from './fields/DetailPhone';
import DetailText from './fields/DetailText';

const SessionDetails: React.FC<{ details: IEnrollmentDetails }> = ({ details }) => {
    const fullstoryLink = process.env.REACT_APP_FULLSTORY_LINK;
    const fieldSets = [
        {
            id: 'set1',
            fields: [
                {
                    label: 'First Name',
                    key: 'mainSubscriberFirstName',
                    render: value => <DetailText value={value} />,
                },
                {
                    label: 'Last Name',
                    key: 'mainSubscriberLastName',
                    render: value => <DetailText value={value} />,
                },
                {
                    label: 'Email',
                    key: 'email',
                    render: value => <DetailEmail value={value} />,
                },
                {
                    label: 'Address',
                    key: 'address',
                    render: value => <DetailText value={value} />,
                },
                {
                    label: 'Last Completed Step',
                    key: 'lastStep',
                    render: value => <DetailText value={value.replaceAll('_', ' ')} />,
                },
            ],
        },
        {
            id: 'set2',
            fields: [
                {
                    label: 'Subscriber No.',
                    key: 'primary',
                    render: value => {
                        return <DetailPhone value={value} />;
                    },
                },
                {
                    label: 'SmartScan ID',
                    key: 'recordId',
                    render: value => (
                        fullstoryLink ? (
                            <Link href={`${fullstoryLink.replaceAll('RECORD_ID', details.recordId)}`} isExternal>
                                <DetailText value={value} />
                            </Link >) : (
                            <DetailText value={value} />
                        )
                    ),
                },
                {
                    label: 'Browser User',
                    key: 'browser',
                    render: value => {
                        // const value =
                        //     data['browserAgentFirst'] + ' ' + data['browserAgentSecond'];
                        return <DetailText value={value} />;
                    },
                },
            ],
        },
    ];
    return (
        <>
            <Details
                componentId="sessionDetails"
                details={details}
                fieldSets={fieldSets}
            />
        </>
    );
};
export default SessionDetails;
