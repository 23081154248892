import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { IEnrollmentDetails } from '../../config/interface';

interface Props {
    componentId: string;
    fieldSets: any[];
    details?: IEnrollmentDetails;
}

const Details: React.FC<Props> = ({ componentId, fieldSets, details }) => {
    return (
        <>
            {fieldSets?.map((set, x) => {
                const id = set.id;
                const fields = set.fields;
                return (
                    <HStack
                        mb={'40px'}
                        spacing={'30px'}
                        key={`hstack-${id}-${x}`}
                        id={`${componentId}-${id}`}
                        align={'baseline'}
                    >
                        {fields.map((field, y) => {
                            const key = field.key;
                            const label = field.label;
                            let value;
                            if (key) {
                                value = details ? details[key] : '';
                            } else {
                                value = details;
                            }
                            const icon = field && field.icon ? field.icon : <></>;
                            return (
                                <Box
                                    key={`box-field-${key}-${y}`}
                                    id={`box-field-${key}-${y}`}
                                >
                                    <Text variant={'fieldLabel'}>{label}</Text>
                                    {field.render(value, icon)}
                                </Box>
                            );
                        })}
                    </HStack>
                );
            })}
        </>
    );
};
export default Details;
