import * as React from 'react';

const RejectedMediaSvg = () => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.427734" y="0.650391" width="50" height="50" rx="10" fill="#DB1B1B" />
        <path
            d="M19.8298 17.6483L25.4277 23.2462L31.0257 17.6483L33.4298 20.0525L27.8319 25.6504L33.4298 31.2483L31.0257 33.6525L25.4277 28.0546L19.8298 33.6525L17.4257 31.2483L23.0236 25.6504L17.4257 20.0525L19.8298 17.6483Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.4277 25.6504C42.4277 35.0392 34.8166 42.6504 25.4277 42.6504C16.0389 42.6504 8.42773 35.0392 8.42773 25.6504C8.42773 16.2615 16.0389 8.65039 25.4277 8.65039C34.8166 8.65039 42.4277 16.2615 42.4277 25.6504ZM39.0277 25.6504C39.0277 33.1615 32.9388 39.2504 25.4277 39.2504C17.9167 39.2504 11.8277 33.1615 11.8277 25.6504C11.8277 18.1393 17.9167 12.0504 25.4277 12.0504C32.9388 12.0504 39.0277 18.1393 39.0277 25.6504Z"
            fill="white"
        />
    </svg>
);

export default RejectedMediaSvg;
