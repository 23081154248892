import * as React from 'react';

const WorkflowRedoSvg = props => (
    <svg width={56} height={70} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <circle cx={28} cy={24} r={24} fill="#F1822A" />
            <path
                d="M39.159 11.077v7.28h-7.28"
                stroke="#fff"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.159 18.359c-3.907-5.373-11.411-6.601-16.827-2.753a11.968 11.968 0 0 0-.114 19.39"
                stroke="#fff"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.12 59.2h3.57c.952 0 1.685.257 2.198.77.523.504.784 1.176.784 2.016 0 .653-.159 1.213-.476 1.68-.317.457-.77.775-1.358.952L17.554 69h-2.03l-2.632-4.228h-.952V69h-1.82v-9.8Zm3.584 3.892c.355 0 .635-.089.84-.266.205-.177.308-.457.308-.84 0-.383-.103-.663-.308-.84-.205-.177-.485-.266-.84-.266H11.94v2.212h1.764Zm10.997 2.94H19.83c.075.523.257.92.546 1.19.3.27.677.406 1.134.406.392 0 .72-.089.98-.266.271-.177.514-.453.729-.826l1.4.518c-.29.663-.696 1.171-1.218 1.526-.514.355-1.176.532-1.988.532-.766 0-1.405-.177-1.918-.532a3.322 3.322 0 0 1-1.12-1.372 4.473 4.473 0 0 1-.35-1.736c0-.597.116-1.171.35-1.722a3.21 3.21 0 0 1 1.12-1.372c.513-.364 1.152-.546 1.918-.546.737 0 1.353.177 1.848.532.494.345.858.784 1.091 1.316.234.532.35 1.078.35 1.638v.714Zm-1.777-1.372a1.89 1.89 0 0 0-.505-.966c-.242-.252-.578-.378-1.008-.378-.373 0-.695.117-.966.35-.27.224-.462.555-.573.994h3.052Zm6.027 4.452c-.98 0-1.74-.327-2.282-.98-.532-.663-.798-1.55-.798-2.66s.266-1.993.798-2.646c.541-.663 1.302-.994 2.282-.994.401 0 .765.075 1.092.224.336.14.588.317.756.532v-3.5h1.736V69H30.8v-.728c-.112.215-.336.41-.672.588-.326.168-.718.252-1.176.252Zm.378-1.484a1.42 1.42 0 0 0 1.162-.56c.308-.383.462-.915.462-1.596s-.154-1.209-.462-1.582a1.407 1.407 0 0 0-1.162-.574c-.476 0-.868.191-1.176.574-.308.373-.462.9-.462 1.582 0 .672.154 1.2.462 1.582.308.383.7.574 1.176.574Zm8.357 1.484c-.738 0-1.377-.159-1.918-.476a3.216 3.216 0 0 1-1.246-1.302c-.28-.55-.42-1.171-.42-1.862 0-.69.14-1.311.42-1.862.289-.55.704-.985 1.246-1.302.541-.317 1.18-.476 1.918-.476.737 0 1.376.159 1.918.476.541.317.952.751 1.232 1.302.289.55.434 1.171.434 1.862 0 .69-.145 1.311-.434 1.862-.28.55-.691.985-1.232 1.302-.542.317-1.181.476-1.918.476Zm0-1.484c.541 0 .97-.205 1.288-.616.317-.42.476-.933.476-1.54s-.159-1.115-.476-1.526c-.318-.42-.747-.63-1.288-.63-.542 0-.971.21-1.288.63-.318.41-.476.92-.476 1.526 0 .607.158 1.12.476 1.54.317.41.746.616 1.288.616Z"
                fill="#F1822A"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h56v70H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default WorkflowRedoSvg;
