/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Divider,
    Flex,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spinner,
    Text,
} from '@chakra-ui/react';
import LogoutSvg from '../assets/LogoutSvg';
import { account, activeRegion } from '../entities/session';

import VerticalCenter from './layouts/VerticalCenter';
import { Navigate } from 'react-router-dom';
import { AppConfig, AppFeatures, AppRoutes } from '../config/config';
import ConfirmDialog from './ConfirmDialog';
import { workerActivityList, workerStatus } from '../entities/twilio';
import { useMutation } from '@tanstack/react-query';
import { updateWorkerStatus } from '../services/apiService';
import { isFeatureEnabled } from '../lib/helpers';

const activityColors = {
    available: '#6EFAC3',
    offline: '#D8D8D8',
    unavailable: '#D8D8D8',
};

const profileFields = [
    { key: 'friendlyName', label: 'email' },
    { key: 'sid', label: 'SID' },
];

const MenuMainTitle = ({ children }) => {
    return (
        <Text p={'3px 10px'} fontSize={'14px'} color={'asuPurple.500'}>
            {children}
        </Text>
    );
};

const MenuItemText = ({ children }) => {
    return (
        <Text p={'3px 10px'} fontSize={'12px'} fontWeight={400}>
            {children}
        </Text>
    );
};

const HeaderMenu: React.FC<{ statusUpdate?: string }> = ({ statusUpdate }) => {
    const accountInfo: any = account.use();
    const currentStatus: any = workerStatus.use();
    const activityList: any = workerActivityList.use();
    const region: any = activeRegion.use();
    const [clickedLogout, setClickedLogout] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [activityStatus, setActivityStatus] = useState(currentStatus);
    const menuStyle = {
        fontSize: '16px',
        fontWeight: 700,
    };

    const dividerStyle = {
        h: '28px',
        borderColor: 'white',
        margin: '19px 16px',
    };

    let ActivityMenuItems: any = [];
    for (let label in activityList) {
        const sid = activityList[label];
        const statusName = label.toLowerCase();
        ActivityMenuItems.push(
            <MenuItem
                _focus={{ bg: 'asuPurple.500' }}
                key={`menuitem-status-${sid}`}
                onClick={() => {
                    onChangeStatus(statusName);
                }}
            >
                <Flex direction={'row'} _focus={{ bg: 'asuPurple.500' }}>
                    <Box
                        borderRadius={'50%'}
                        backgroundColor={activityColors[statusName]}
                        w={'12px'}
                        h={'12px'}
                        m={'3px 4px'}
                    ></Box>
                    <Text fontSize={'14px'} textTransform={'capitalize'}>
                        {label}
                    </Text>
                </Flex>
            </MenuItem>
        );
    }

    const { mutate: updateStatus } = useMutation(updateWorkerStatus, {
        onSuccess: data => {
            console.info('Status Updated');
        },
        onError: () => {
            console.error('Error on Status Update');
        },
    });

    const onChangeStatus = newStatus => {
        if (currentStatus !== newStatus) {
            console.info(
                `Expert changing status from ${currentStatus.toUpperCase()} to ${newStatus.toUpperCase()}`
            );
            workerStatus.set(newStatus);
            setActivityStatus(newStatus);
            updateStatus({ region, workerSid: accountInfo?.sid, status: newStatus });
        }
    };

    useEffect(() => {
        if (statusUpdate && statusUpdate !== activityStatus) {
            workerStatus.set(statusUpdate);
            setActivityStatus(statusUpdate);

            console.info(`New Status Received from ${activityStatus} to ${statusUpdate}`);
        }
    }, [statusUpdate, activityStatus]);

    useEffect(() => {
        if (!currentStatus && activityList) {
            workerStatus.set('Unavailable');
            setActivityStatus('Unavailable');
        }
    }, [currentStatus, activityList]);

    return (
        <Flex direction={'row'} {...menuStyle}>
            <ConfirmDialog
                title="Logout"
                open={openConfirmModal}
                content="Are you sure you want to logout?"
                onYes={() => {
                    setClickedLogout(true);
                }}
                onCancel={() => {
                    setOpenConfirmModal(false);
                }}
            />
            <Box my={'19px'}>{AppConfig?.defaultRegion?.toUpperCase()}</Box>
            <Divider orientation="vertical" {...dividerStyle} />
            <Box my={'19px'} {...menuStyle}>
                English
            </Box>
            <Divider orientation="vertical" {...dividerStyle} />

            <Menu autoSelect={false} variant={'headermenu'}>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant={'menubutton'}
                >
                    <Text>{accountInfo?.name || accountInfo?.friendlyName}</Text>
                    {isFeatureEnabled(AppFeatures.WORKER_STATUS) && (
                        <Flex direction={'row'} _focus={{ bg: 'asuPurple.500' }}>
                            <Box
                                borderRadius={'50%'}
                                backgroundColor={
                                    activityColors[activityStatus?.toLowerCase()]
                                }
                                w={'12px'}
                                h={'12px'}
                                m={'3px 4px'}
                            ></Box>
                            <Text textTransform={'capitalize'}>{activityStatus}</Text>
                        </Flex>
                    )}
                </MenuButton>
                <MenuList
                    backgroundColor={'black'}
                    border={'none'}
                    borderRadius={0}
                    top={'-5px'}
                >
                    {isFeatureEnabled(AppFeatures.WORKER_STATUS) && (
                        <>
                            <MenuMainTitle>Status</MenuMainTitle>
                            {ActivityMenuItems}

                            <MenuDivider />
                        </>
                    )}
                    <MenuMainTitle>Roles</MenuMainTitle>
                    {accountInfo &&
                        accountInfo?.roles.map((role, i) => {
                            return (
                                <MenuItemText key={`role-${i}`}>
                                    {role.toUpperCase()}
                                </MenuItemText>
                            );
                        })}
                    <MenuDivider />
                    <MenuMainTitle>Carrier & Skills</MenuMainTitle>

                    {accountInfo &&
                        accountInfo?.carrierSkills.map((item, i) => {
                            return (
                                <Flex direction={'column'} key={`carrier-${i}`}>
                                    <Box>
                                        <Flex direction={'row'}>
                                            <MenuItemText>
                                                {item.carrier.toUpperCase()}
                                            </MenuItemText>
                                            <Box>
                                                <MenuItemText>
                                                    {item.skills
                                                        ?.join(', ')
                                                        .toLowerCase()}
                                                </MenuItemText>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                            );
                        })}
                    <MenuDivider />
                    <MenuMainTitle>Profile</MenuMainTitle>
                    {profileFields.map((field, i) => {
                        const { key, label } = field;
                        return (
                            <MenuItemText key={`profile-menu-${i}`}>
                                {label?.toUpperCase()}
                                <br />
                                {accountInfo[key] ? accountInfo[key].toLowerCase() : ''}
                            </MenuItemText>
                        );
                    })}
                </MenuList>
            </Menu>
            <Button
                variant={'menubutton'}
                onClick={() => {
                    setOpenConfirmModal(true);
                }}
            >
                <LogoutSvg />
            </Button>
            {clickedLogout && <Navigate to={AppRoutes.LOGOUT} />}
        </Flex>
    );
};

export default HeaderMenu;
