import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    IconButton,
    Spinner,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import NotesCloseSvg from '../../assets/NotesCloseSvg';
import NotesFloatSvg from '../../assets/NotesFloatSvg';
import { fetchReviewerNotes } from '../../services/apiService';
import VerticalCenter from '../layouts/VerticalCenter';
import DetailDateTime from './fields/DetailDateTime';

function useNotes(): any {
    return useQuery(['notes'], fetchReviewerNotes);
}

const ReviewNotes: React.FC = () => {
    const [toggleNoteForm, setToggleNoteForm] = useState(false);
    const [previousNotes, setPreviousNotes] = useState(Array<any>);
    const [notes, setNotes] = useState<any>();

    const { data, isFetching } = useNotes();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    if (!isFetching) {
        if (data && data.length > 0 && previousNotes.length === 0) {
            setPreviousNotes(data);
        }
    }

    const submitFormHandler = values => {
        const data = { notes: values.notes };
        console.info('Notes added', data);
        setNotes('');
    };

    return (
        <>
            <Box
                id={'review-notes-action'}
                position="absolute"
                left={`calc(100% - 130px)`}
                background={'transparent'}
            >
                {!toggleNoteForm && (
                    <IconButton
                        icon={<NotesFloatSvg />}
                        aria-label={'Notes'}
                        variant={'outline'}
                        border={'none'}
                        onClick={() => {
                            setToggleNoteForm(true);
                        }}
                        borderRadius={'50%'}
                        m={0}
                        p={0}
                        w={'60px'}
                        h={'86px'}
                        _hover={{
                            border: 'none',
                            background: 'transparent',
                            opacity: '100',
                        }}
                        _active={{
                            border: 'none',
                            background: 'none',
                        }}
                        _selected={{
                            border: 'none',
                            background: 'none',
                        }}
                    />
                )}

                {toggleNoteForm && (
                    <>
                        <IconButton
                            icon={<NotesCloseSvg />}
                            aria-label={'Notes'}
                            variant={'outline'}
                            border={'none'}
                            m={0}
                            p={0}
                            w={'60px'}
                            h={'86px'}
                            borderRadius={'50%'}
                            onClick={() => {
                                setToggleNoteForm(false);
                            }}
                            _hover={{
                                border: 'none',
                                background: 'transparent',
                                opacity: '100',
                            }}
                            _active={{
                                border: 'none',
                                background: 'none',
                            }}
                            _selected={{
                                border: 'none',
                                background: 'none',
                            }}
                        />
                    </>
                )}
            </Box>
            {toggleNoteForm && (
                <Box
                    id={'review-notes-form'}
                    w={'480px'}
                    h={'783px'}
                    boxShadow={`0px 0px 12px 6px rgba(0, 0, 0, 0.25)`}
                    backgroundColor={'white'}
                    position="absolute"
                    left={`calc(100% - 550px)`}
                    marginTop={'90px'}
                    borderRadius={'10px'}
                    zIndex={'999'}
                    p={'16px'}
                >
                    <form onSubmit={handleSubmit(submitFormHandler)}>
                        <FormControl isInvalid={errors && errors.notes ? true : false}>
                            <Text fontSize={'18px'} fontWeight={700}>
                                Notes
                            </Text>
                            <Textarea
                                id={'notes'}
                                {...register('notes', { required: 'This is required' })}
                                placeholder="Type notes here..."
                                h="280px"
                                w={'full'}
                                borderRadius="8px"
                                resize={'none'}
                                value={notes}
                                onChange={e => {
                                    setNotes(e.target.value);
                                }}
                            />
                            {errors.notes && errors.notes.message && (
                                <FormErrorMessage>
                                    {errors.notes.message.toString()}
                                </FormErrorMessage>
                            )}
                            <Button
                                type={'submit'}
                                variant={'solid'}
                                w={'full'}
                                mt={'24px'}
                                mb={'24px'}
                            >
                                Submit
                            </Button>
                        </FormControl>
                    </form>
                    <Box>
                        <Divider />
                        <Text fontSize={'18px'} fontWeight={700} mt={'16px'} mb={'8px'}>
                            Previous Notes
                        </Text>
                        {isFetching && (
                            <VerticalCenter
                                innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                                h={'100px'}
                            >
                                <Spinner variant="componentLoader" />
                            </VerticalCenter>
                        )}
                        {!isFetching && previousNotes.length > 0 && (
                            <Box>
                                <Flex direction={'column'}>
                                    {previousNotes.map((note, index) => {
                                        return (
                                            <Box
                                                key={`note-${index}`}
                                                borderBottomColor={'asuGray.600'}
                                                borderBottomStyle={'solid'}
                                                borderBottomWidth={'1px'}
                                                mb={'4px'}
                                                pb={'4px'}
                                            >
                                                <Flex direction={'row'}>
                                                    <Box mb={'10px'}>
                                                        <Text
                                                            fontSize={'16px'}
                                                            fontWeight={400}
                                                            color={'black'}
                                                        >
                                                            {note.reviewer}
                                                        </Text>
                                                    </Box>
                                                    <Box m={'3px 0 5px 8px'}>
                                                        <DetailDateTime
                                                            value={note.commentDate}
                                                            props={{
                                                                fontSize: '12px',
                                                                fontWeight: 400,
                                                            }}
                                                        />
                                                    </Box>
                                                </Flex>
                                                <Box>
                                                    <Text
                                                        fontSize={'18px'}
                                                        fontWeight={400}
                                                        color={'black'}
                                                    >
                                                        {note.comment}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ReviewNotes;
