import { Flex, Text, Box } from '@chakra-ui/react';
import React from 'react';
import { IRecordPhoto } from '../../../config/interface';

interface AIReviewDisplayProps {
    photo: IRecordPhoto;
    aiPhoto?: any;
    detectedDevice: string;
}

const AIReviewDisplay: React.FC<AIReviewDisplayProps> = ({
    photo,
    aiPhoto,
    detectedDevice,
}) => {
    const { dataPoints } = photo;
    const confidencePass = dataPoints?.confidenceScore >= dataPoints?.confidenceThreshold;
    const sharpnessPass = dataPoints?.sharpnessScore >= dataPoints?.sharpnessThreshold;
    const screen = dataPoints?.detectedScreen;

    return (
        <Flex direction="row" justifyContent="space-between">
            <Flex direction="column" h="70px" w="74px">
                <Text variant="aiReviewLabel">Confidence</Text>
                <Flex justifyContent="center" alignItems="center" h="52px">
                    <Box
                        borderRadius="50%"
                        bgColor={confidencePass ? 'green' : 'red'}
                        w="16px"
                        h="16px"
                    />
                </Flex>
            </Flex>
            <Flex direction="column" h="70px" w="65px">
                <Text variant="aiReviewLabel">Sharpness</Text>
                <Flex justifyContent="center" alignItems="center" h="52px">
                    <Box
                        borderRadius="50%"
                        bgColor={sharpnessPass ? 'green' : 'red'}
                        w="16px"
                        h="16px"
                    />
                </Flex>
            </Flex>
            <Flex
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                h="70px"
                w="60px"
            >
                <Text variant="aiReviewLabel">Screen</Text>
                <Flex
                    w="48px"
                    h="48px"
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    textTransform={screen === 'imei' ? 'uppercase' : 'capitalize'}
                >
                    {screen === 'off' ? '-' : screen}
                </Flex>
            </Flex>
            {(photo.imageType === 'front' || photo.imageType === 'back') && (
                <>
                    <Flex direction="column" h="70px" w="40px">
                        <Text variant="aiReviewLabel">Grade</Text>
                        <Text
                            fontSize="24px"
                            fontWeight="700"
                            lineHeight="52px"
                            textAlign="center"
                        >
                            {aiPhoto?.dataPoints?.grade || '-'}
                        </Text>
                    </Flex>
                    {aiPhoto?.dataPoints?.bullseyeCount ? (
                        <>
                            <Flex direction="column" h="70px" w="55px">
                                <Text variant="aiReviewLabel">Bullseye</Text>
                                <Text
                                    fontSize="24px"
                                    fontWeight="700"
                                    lineHeight="52px"
                                    textAlign="center"
                                >
                                    {aiPhoto?.dataPoints?.bullseyeCount?.toString() || '-'}
                                </Text>
                            </Flex>
                            <Flex direction="column" h="70px" w="30px">
                                <Text variant="aiReviewLabel">Line</Text>
                                <Text
                                    fontSize="24px"
                                    fontWeight="700"
                                    lineHeight="52px"
                                    textAlign="center"
                                >
                                    {aiPhoto?.dataPoints?.lineCount?.toString() || '-'}
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <Flex direction="column" h="70px" w="44px">
                            <Text variant="aiReviewLabel">Cracks</Text>
                            <Text
                                fontSize="24px"
                                fontWeight="700"
                                lineHeight="52px"
                                textAlign="center"
                            >
                                {aiPhoto?.dataPoints?.crackCount?.toString()}
                            </Text>
                        </Flex>
                    )}
                </>
            )}
            {photo.imageType === 'imei' && (
                <Flex direction="column" h="70px" w="113px" alignItems="center">
                    <Text variant="aiReviewLabel">Make & Model</Text>
                    <Flex alignItems="center" h="52px">
                        <Text fontSize="14px" lineHeight="130%" textAlign="center">
                            {detectedDevice === ' ' ? '-' : detectedDevice}
                        </Text>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};

export default AIReviewDisplay;
