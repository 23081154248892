import * as React from 'react';

const PlayButtonSvg = () => (
    <svg
        width="82"
        height="83"
        viewBox="0 0 82 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="41.1301" cy="41.6633" r="40.8" fill="#111111" fillOpacity="0.9" />
        <path
            d="M54.3893 41.6619L36.0293 53.9019V29.4219L54.3893 41.6619Z"
            fill="white"
        />
    </svg>
);

export default PlayButtonSvg;
