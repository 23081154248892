import { Box, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AppFeatures } from '../../../config/config';
import { isFeatureEnabled } from '../../../lib/helpers';
import DetailEditableText from './DetailEditableText';

const DetailImeiVerifyField: React.FC<{
    recordId: string;
    reviewer: string;
    status: string;
    imei: string;
    onUpdate?: Function;
}> = ({ recordId, imei, reviewer = '', status, onUpdate = () => {} }) => {
    const [errorMsg, setErrorMsg] = useState<any>([]);

    const enableValidation = isFeatureEnabled(AppFeatures.IMEI_UPDATE_VALIDATION);

    return (
        <Flex direction={'row'} id={`imei-${recordId}`}>
            <Box>
                <DetailEditableText
                    recordId={recordId}
                    reviewer={reviewer}
                    fieldKey={'imei'}
                    value={imei}
                    status={status}
                    validator={
                        enableValidation
                            ? {
                                  minLength: 15,
                                  maxLength: 15,
                                  numbersOnly: true,
                              }
                            : {}
                    }
                    onChange={(id, fieldKey, fieldValue) => {
                        setErrorMsg([]);
                        onUpdate(id, fieldKey, fieldValue);
                    }}
                    onError={errorCodes => {
                        if (enableValidation) {
                            setErrorMsg([]);

                            let messages: any = [];

                            if (
                                errorCodes.indexOf('MIN_LENGTH') > -1 ||
                                errorCodes.indexOf('MAX_LENGTH') > -1
                            ) {
                                messages.push(
                                    <Box>IMEI must be 15 characters in length.</Box>
                                );
                            }

                            if (errorCodes.indexOf('NUMBERS_ONLY') > -1) {
                                messages.push(<Box>IMEI must be numbers only.</Box>);
                            }

                            if (messages && messages.length > 0) {
                                setErrorMsg(messages);
                            }
                        }
                    }}
                    onCancel={() => setErrorMsg([])}
                />
                {errorMsg && errorMsg.length > 0 && enableValidation && (
                    <Box color={'red'} fontSize={'14px'}>
                        {errorMsg}
                    </Box>
                )}
            </Box>
        </Flex>
    );
};

export default DetailImeiVerifyField;
