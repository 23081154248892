import * as React from 'react';

const EditableSvg = props => (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="m15.956 2.35 3.695 3.695L6.543 19.153l-3.695-3.695L15.956 2.348ZM2.847 15.457 1 21l5.543-1.847-3.696-3.696v0Z"
            stroke="#8223D2"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="m19.65 6.044-3.694-3.695.616-.616a2.613 2.613 0 0 1 3.695 3.695l-.616.616Z"
            stroke="#8223D2"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default EditableSvg;
