import * as React from 'react';

const FilterPhoneSvg = (props: any) => (
    <svg
        width={props.width || 10}
        height={props.height || 17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.667.5h6.666C9.253.5 10 1.217 10 2.1v12.8c0 .883-.748 1.6-1.667 1.6H1.667C.747 16.5 0 15.783 0 14.9V2.1C0 1.217.748.5 1.667.5Zm6.666 3.2V2.1H1.667v1.6h6.666Zm0 8V5.3H1.667v6.4h6.666Zm-6.666 1.6v1.6h6.667v-1.6H1.667Z"
            fill="#000"
        />
    </svg>
);

export default FilterPhoneSvg;
