import * as React from 'react';

const FlipImgSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={24} cy={24} r={24} fill="#111" fillOpacity={0.9} />
        <path
            d="M16 16h5v-2h-5a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h5v-2h-5V16ZM29 14h-2v2h2v-2ZM27 32h2v2h-2v-2ZM32 14h-1v2h1v1h2v-1c0-1.103-.896-2-2-2ZM31 32h1v-1h2v1a2 2 0 0 1-2 2h-1v-2ZM34 19h-2v2h2v-2ZM32 27h2v2h-2v-2ZM34 23h-2v2h2v-2Z"
            fill="#fff"
        />
        <path
            d="m26.586 22-1.293-1.293 1.414-1.414L30.414 23l-3.707 3.707-1.414-1.414L26.586 24H23a2 2 0 0 0-2 2h-2c0-2.205 1.795-4 4-4h3.586ZM25 14h-2v5h2v-5ZM23 27h2v7h-2v-7Z"
            fill="#fff"
        />
    </svg>
);

export default FlipImgSvg;
