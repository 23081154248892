import {
    Button,
    Center,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

const ConfirmDialog: React.FC<{
    title?: string;
    content?: any;
    open?: boolean;
    yesLabel?: string;
    noLabel?: string;
    onYes?: Function;
    onCancel?: Function;
}> = ({
    title = 'Confirm',
    content,
    open = false,
    yesLabel = 'Yes',
    noLabel = 'No',
    onYes = () => {},
    onCancel = () => {},
}) => {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleYes = () => {
        if (onYes) {
            onYes();
        }
    };

    return (
        <Modal
            isOpen={open}
            onClose={handleCancel}
            isCentered={true}
            onEsc={handleCancel}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>{title}</Center>
                </ModalHeader>
                <ModalBody>
                    <Center>{content}</Center>
                </ModalBody>
                <Center>
                    <ModalFooter>
                        <Button
                            variant="outline"
                            colorScheme={'asuPurple'}
                            onClick={handleCancel}
                            minW={'99p'}
                            minH={'44px'}
                            p={'12px 24px'}
                            mr={'5px'}
                        >
                            {noLabel}
                        </Button>

                        <Button
                            colorScheme={'asuPurple'}
                            onClick={handleYes}
                            minW={'99p'}
                            minH={'44px'}
                            p={'12px 24px'}
                            ml={'5px'}
                        >
                            {yesLabel}
                        </Button>
                    </ModalFooter>
                </Center>
            </ModalContent>
        </Modal>
    );
};
export default ConfirmDialog;
