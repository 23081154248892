import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
    children?: ReactNode;
    styleProps?: any;
}

const BorderedBox: React.FC<Props> = ({ children, styleProps }) => {
    return (
        <Box
            borderColor={'asuGray.500'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderRadius={'10px'}
            p={'10px'}
            minWidth={'420px'}
            width={'auto'}
            marginBottom={'15px'}
            backgroundColor="asuWhite.300"
            {...styleProps}
        >
            {children}
        </Box>
    );
};

export default BorderedBox;
