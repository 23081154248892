import { Box, Center } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props {
    id?: string;
    children?: ReactNode;
    innerProps: any;
    h?: string;
    m?: string;
}

const VerticalCenter: React.FC<Props> = ({ children, id, innerProps, h, m = '0' }) => {
    return (
        <Box position="relative" h={h || '100vh'} id={`vc-root-${id}`} m={m}>
            <Center id={`vc-center-${id}`}>
                <Box
                    {...innerProps}
                    position="absolute"
                    top={'50%'}
                    transform={'translateY(-50%)'}
                    id={`vc-box-${id}`}
                >
                    {children}
                </Box>
            </Center>
        </Box>
    );
};

export default VerticalCenter;
