import * as React from 'react';

const VideoCaptureSvg = props => (
    <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={30} cy={30} r={30} fill="#8223D2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.375 31.111c0-3.063 2.523-5.555 5.625-5.555s5.625 2.49 5.625 5.555c0 3.065-2.523 5.556-5.625 5.556s-5.625-2.491-5.625-5.556Zm2.25 0c0 1.838 1.514 3.333 3.375 3.333 1.86 0 3.375-1.495 3.375-3.333S31.861 27.778 30 27.778c-1.86 0-3.375 1.495-3.375 3.333Z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.102 23.333H39c1.24 0 2.25.997 2.25 2.223v12.222C41.25 39.003 40.24 40 39 40H21c-1.24 0-2.25-.997-2.25-2.222V25.556c0-1.226 1.01-2.223 2.25-2.223h3.898l1.582-2.343a2.253 2.253 0 0 1 1.872-.99h3.296c.754 0 1.454.37 1.872.99l1.582 2.343ZM21 25.556v12.222h18.001L39 25.556h-5.102l-2.25-3.334h-3.296l-2.25 3.334H21Z"
            fill="#fff"
        />
    </svg>
);

export default VideoCaptureSvg;
