import * as React from 'react';

const ApproveScreenSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={24} cy={24} r={24} fill="#00B574" />
        <path
            d="M34.8 12 18.874 34.2 12.6 27.926"
            stroke="#fff"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ApproveScreenSvg;
