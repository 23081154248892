import * as React from 'react';

const AsurionLogoSvg = (props: any) => (
    <svg
        width={props.width || 69}
        height={props.height || 16}
        fill={props.fill || '"none"'}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.665 12.14c0 .93.752 1.488 1.505 1.488.846 0 1.457-.512 1.457-1.395 0-1.256-1.128-1.35-2.21-1.675-1.129-.325-2.351-1.21-2.351-3.023 0-1.814 1.41-3.116 3.15-3.116 1.176 0 2.07.511 2.681 1.441L18.345 7.35c-.188-.465-.564-.744-.987-.744-.612 0-.894.465-.894.883 0 .977 1.27.977 2.492 1.396 1.176.418 2.21 1.302 2.21 3.209 0 2.233-1.645 3.768-3.95 3.768-2.35 0-3.856-1.722-3.856-3.815h2.305v.094ZM32.17 4.604h-2.493v6.186c0 1.674-.846 2.837-2.492 2.837s-2.492-.837-2.492-2.837V4.605H22.2v6.744c0 2.977 1.552 4.604 4.374 4.604 1.552 0 2.586-.744 3.103-1.395v1.256h2.493V4.604Zm1.975 11.255h2.492V9.628c0-1.675 1.129-2.79 2.68-2.79h.236V4.464c-1.6 0-2.398.605-2.916 1.302V4.605h-2.492V15.86Zm7.054 0h2.492V4.605H41.2V15.86ZM42.47 0c-.941 0-1.694.744-1.694 1.674 0 .93.753 1.675 1.693 1.675.94 0 1.693-.744 1.693-1.675C44.162.744 43.41 0 42.47 0Zm8.558 13.581c-1.928 0-3.245-1.395-3.245-3.348 0-1.954 1.317-3.35 3.245-3.35 1.928 0 3.245 1.396 3.245 3.35.047 1.953-1.27 3.348-3.245 3.348Zm0-9.07c-3.339 0-5.784 2.466-5.784 5.722 0 3.255 2.445 5.72 5.784 5.72s5.784-2.465 5.784-5.72c.047-3.256-2.398-5.721-5.784-5.721Zm17.494 5.024c0-2.837-2.352-5.163-5.22-5.163-2.869 0-5.22 2.326-5.22 5.163v6.279h2.445v-6.28c0-1.487 1.223-2.743 2.775-2.743 1.505 0 2.774 1.209 2.774 2.744v6.279h2.446v-6.28ZM6.307 13.488c1.834 0 3.291-1.488 3.291-3.255 0-1.814-1.504-3.256-3.291-3.256-1.834 0-3.292 1.488-3.292 3.256-.047 1.813 1.458 3.255 3.292 3.255Zm0-8.976c3.197 0 5.784 2.558 5.784 5.72v5.629H9.598v-1.256C8.611 15.72 7.388 16 6.307 16 3.109 16 .522 13.442.522 10.28.475 7.07 3.11 4.511 6.307 4.511Z"
            fill={props.fill || '#111'}
        />
    </svg>
);

export default AsurionLogoSvg;
