import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Center,
    Flex,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import SubmissionDetails from '../components/enrollment/SubmissionDetails';
import FullWidth from '../components/FullWidth';
import VerticalCenter from '../components/layouts/VerticalCenter';
import {
    AppConfig,
    AppFeatures,
    AppRoutes,
    AppScreen,
    NotificationStatus,
    STATUS,
    UserRoles,
} from '../config/config';
import {
    assignTask,
    fetchEnrollmentDetails,
    fetchReviewerChecklist,
    unAssignTask,
    updateEnrollmentDetails,
} from '../services/apiService';
import BorderedBox from '../components/layouts/BorderedBox';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import ReviewPhotos from '../components/enrollment/ReviewPhotos';
import ReviewVideos from '../components/enrollment/ReviewVideos';
import ReviewDocuments from '../components/enrollment/ReviewDocuments';
import SessionDetails from '../components/enrollment/SessionDetails';
import ReviewerChecklist from '../components/enrollment/ReviewerChecklist';
import ReviewNotes from '../components/enrollment/ReviewNotes';
import ReviewClose from '../components/enrollment/ReviewClose';
import { latestUploadedSpf, updatedEnrollment } from '../entities/enrollments';
import { account } from '../entities/session';
import { IEnrollmentDetails, IPhotosScoreGtm } from '../config/interface';
import { isFeatureEnabled, parseErrorResponse } from '../lib/helpers';
import useAnalytics from '../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../lib/analytics';

const DetailTab: React.FC<{ title: string }> = ({ title }) => {
    return (
        <Tab
            fontSize={'18px'}
            fontWeight={400}
            color={'black'}
            paddingLeft={0}
            marginLeft={'10px'}
            textAlign={'left'}
            _active={{
                color: 'black',
                fontWeight: 700,
            }}
            _selected={{
                color: 'black',
                fontWeight: 700,
                borderColor: 'asuPurple.500',
                borderBottomWidth: '6px',
                borderBottomStyle: 'solid',
            }}
            _hover={{
                color: 'black',
                fontWeight: 700,
            }}
        >
            {title}
        </Tab>
    );
};

const EnrollmentDetailsPage: FC = () => {
    // temporarily holds the key-value pair object to be updated
    let updatedFields: any = {};

    const accountInfo: any = account.use();

    const { primary, secondary } = useParams();
    const { addEvent } = useAnalytics();

    const [enrollmentDetails, setEnrollmentDetails] = useState(Array<IEnrollmentDetails>);
    const [showRecordSection, setShowRecordSection] = useState(0);
    const [reviewerWorkflowData, setReviewerWorkflowData] = useState<any>(null);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [uploadedSpfFiles, setUploadedSpfFiles] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [updateTimestamp, setUpdateTimestamp] = useState<any>();
    const [userRoles, setUserRoles] = useState<UserRoles[]>([]);
    const [updatedRecordId, setUpdatedRecordId] = useState<string>('');
    const [enrollmentDetailsError, setEnrollmentDetailsError] = useState<any>(null);
    const [listUpdatedIMEI, setListUpdatedIMEI] = useState<any>(null);
    const [photosScore, setPhotosScore] = useState<Partial<IPhotosScoreGtm>>();

    const forceUpdate = () => {
        setUpdateTimestamp(new Date().getTime());
    };

    // Fetch enrollment details
    function useEnrollmentDetails(primary, secondary): any {
        return useQuery(['enrollment', primary, secondary], () =>
            fetchEnrollmentDetails(primary, secondary)
        );
    }
    const {
        data: enrollmentData,
        isFetching: isEnrollmentFetching,
        error: enrollmentError,
    } = useEnrollmentDetails(primary, secondary);

    // Fetch checklist by carrier
    function useChecklist(carrier: string): any {
        return useQuery(['checklist', carrier], () => {
            return fetchReviewerChecklist(carrier);
        }, {
            enabled: carrier !== ''
        });
    }
    const { data: checklistData, isFetching: isChecklistFetching } =
        useChecklist(selectedCarrier);

    const updateEnrollmentDetailFields = (recordIdToUpdate: string) => {
        // this will update states and re-render UI
        let details = enrollmentDetails;
        for (let x = 0; x < details.length; x++) {
            let detail = details[x];
            const recordId = detail.recordId;
            if (recordIdToUpdate === recordId) {
                for (let key in updatedFields) {
                    const value = updatedFields[key];
                    detail[key] = value;
                    console.info(
                        `Updating ${key?.toUpperCase()} with new value`,
                        value,
                        detail
                    );
                }
            }
        }
        updatedFields = {};
        setEnrollmentDetails(details);
    };

    // Update field of enrollment details
    const { mutate: updateField } = useMutation(updateEnrollmentDetails, {
        onSuccess: data => {
            const { details } = data;
            const { message, updated, detectedDevice, detectedDeviceMismatch } = details;

            if (message === 'OK') {
                updatedEnrollment.set({
                    id: `record-update-success-${updated}`,
                    title: 'Session updated',
                    description: `A session with record Id [${updated}] has been updated`,
                    status: NotificationStatus.SUCCESS,
                });

                // for IMEI update
                if (detectedDevice) {
                    updatedFields.detectedDevice = detectedDevice;
                }
                if (detectedDeviceMismatch) {
                    updatedFields.detectedDeviceMismatch = detectedDeviceMismatch;
                }

                updateEnrollmentDetailFields(updated);
                forceUpdate();
            }
        },
        onError: () => {
            updatedFields = {};
            updatedEnrollment.set({
                id: 'record-update-error',
                title: 'Session updated',
                description: `An error occurred while updating a session.`,
                status: NotificationStatus.ERROR,
            });
        },
    });

    const { mutate: unAssignTaskFromWorker, isLoading: isUnAssigning } = useMutation(
        unAssignTask,
        {
            onSuccess: data => {
                const { updated: recordId } = data;
                updatedFields = {
                    reviewer: '',
                    reviewStatus: STATUS.PENDING,
                    reviewerWorkerSid: '',
                };
                updateEnrollmentDetailFields(recordId);
                // just to force re-render
                forceUpdate();
                setUpdatedRecordId('');
            },
            onError: (error: any) => {
                const _id = updatedRecordId;

                const { message, statusCode } = parseErrorResponse(error);

                if (statusCode === 404) {
                    updatedEnrollment.set({
                        id: `unassign-task-error-${_id}`,
                        title: `UnAssign Task: No record found`,
                        description: message,
                        status: NotificationStatus.ERROR,
                    });
                } else if (statusCode === 400) {
                    updatedEnrollment.set({
                        id: `unassign-task-error-${_id}`,
                        title: `UnAssign Task: Invalid Request`,
                        description: message,
                        status: NotificationStatus.ERROR,
                    });
                }

                setUpdatedRecordId('');
            },
        }
    );

    const { mutate: assignTaskToWorker, isLoading: isAssigning } = useMutation(
        assignTask,
        {
            onSuccess: data => {
                const { updated: recordId } = data;
                updatedFields = {
                    reviewer: accountInfo?.name,
                    reviewStatus: STATUS.ASSIGNED,
                    reviewerWorkerSid: accountInfo?.sid,
                };
                updateEnrollmentDetailFields(recordId);
                // just to force re-render
                forceUpdate();
                setUpdatedRecordId('');
            },
            onError: (error: any) => {
                const _id = updatedRecordId;

                const { message, statusCode } = parseErrorResponse(error);

                if (statusCode === 404) {
                    updatedEnrollment.set({
                        id: `assign-task-error-${_id}`,
                        title: `Assign Task: No record found`,
                        description: message,
                        status: NotificationStatus.ERROR,
                    });
                } else if (statusCode === 400) {
                    updatedEnrollment.set({
                        id: `assign-task-error-${_id}`,
                        title: `Assign Task: Invalid Request`,
                        description: message,
                        status: NotificationStatus.ERROR,
                    });
                }

                setUpdatedRecordId('');
            },
        }
    );

    // Update workflow of enrollment details
    const { mutate: updateWorkflowStatus, isLoading: isUpdatingWorkflow } = useMutation(
        updateEnrollmentDetails,
        {
            onSuccess: data => {
                const { recordId, details } = data;
                const { message, updated, prmStatus, rejectReason, phoneToEnrollNumber } = details;

                let notif = {
                    id: '',
                    title: '',
                    description: <></>,
                    status: '',
                };

                if (message === 'OK') {
                    const { reviewStatus, reviewerChecklist, reviewReason } =
                        updatedFields;

                    if (prmStatus == true) {
                        // approved by PRM
                        notif.id = `record-workflow-success-${updated}`;
                        notif.title = `Enrollment ${reviewStatus}!`;
                        notif.description = (
                            <Box>
                                Enrollment <b>{updated}</b> was {reviewStatus}
                            </Box>
                        );

                        let otherGtmData: any = {};
                        otherGtmData['ReviewerAction'] = reviewStatus;
                        otherGtmData['ChecklistChecked'] = true;
                        otherGtmData['FrontVerified'] = reviewerChecklist?.front || false;
                        otherGtmData['BackVerified'] = reviewerChecklist?.back || false;
                        otherGtmData['IMEIVerified'] = reviewerChecklist?.imei || false;
                        otherGtmData['SPFLostDBVerified'] =
                            reviewerChecklist?.spf || false;

                        // add session details to GTM event
                        const session = enrollmentDetails.find(
                            enrollment => enrollment.recordId === recordId
                        );

                        otherGtmData['OCRConsoleMakeAndModel'] = session?.detectedDevice;
                        otherGtmData['OCRConsoleIMEI'] = session?.imei;
                        otherGtmData['isMakeAndModelDetected'] =
                            session?.detectedDevice && session?.detectedDevice !== ''
                                ? true
                                : false;
                        otherGtmData['isOCRIMEIDetected'] =
                            session?.imei && session?.imei !== '' ? true : false;

                        otherGtmData['SmartscanAssessmentStartDate'] = session?.dateTime;
                        otherGtmData['SmartscanAssessmentSubmitDate'] =
                            session?.submitDate;
                        otherGtmData['ReviewerResponseDate'] = new Date().toISOString();

                        // determine if there was a change on IMEI by reviewer
                        if (listUpdatedIMEI && listUpdatedIMEI[recordId]) {
                            otherGtmData['IsIMEIUpdated'] = true;
                            otherGtmData['UpdatedIMEI'] = listUpdatedIMEI[recordId];
                        }

                        otherGtmData['ConfidenceScoreFront'] =
                            photosScore?.ConfidenceScoreFront;
                        otherGtmData['ConfidenceScoreBack'] =
                            photosScore?.ConfidenceScoreBack;
                        otherGtmData['ConfidenceScoreIMEI'] =
                            photosScore?.ConfidenceScoreIMEI;

                        otherGtmData['SharpnessScoreFront'] =
                            photosScore?.SharpnessScoreFront;
                        otherGtmData['SharpnessScoreBack'] =
                            photosScore?.SharpnessScoreBack;
                        otherGtmData['SharpnessScoreIMEI'] =
                            photosScore?.SharpnessScoreIMEI;
                        otherGtmData['ConfidenceThresholdFront'] =
                            photosScore?.ConfidenceThresholdFront;
                        otherGtmData['SharpnessThresholdFront'] =
                            photosScore?.SharpnessThresholdFront;
                        otherGtmData['ConfidenceThresholdBack'] =
                            photosScore?.ConfidenceThresholdBack;
                        otherGtmData['SharpnessThresholdBack'] =
                            photosScore?.SharpnessThresholdBack;
                        otherGtmData['ConfidenceThresholdIMEI'] =
                            photosScore?.ConfidenceThresholdIMEI;
                        otherGtmData['SharpnessThresholdIMEI'] =
                            photosScore?.SharpnessThresholdIMEI;

                        otherGtmData[`PhoneToEnrollMDN${phoneToEnrollNumber}`] = secondary;

                        console.log(reviewStatus, otherGtmData);

                        if (reviewStatus === STATUS.APPROVED) {
                            otherGtmData.id = 'btn-smartscanconsole-reviewer-approved';
                            addEvent(
                                recordId,
                                ANALYTICS_EVENTS.REVIEWER_APPROVED,
                                otherGtmData
                            );
                            notif.status = NotificationStatus.SUCCESS;
                        } else {
                            otherGtmData.id = 'btn-smartscanconsole-reviewer-rejected';
                            otherGtmData['RejectionReason'] = reviewReason;
                            addEvent(
                                recordId,
                                ANALYTICS_EVENTS.REVIEWER_REJECTED,
                                otherGtmData
                            );
                            notif.status = NotificationStatus.WARNING;
                        }
                    } else if (prmStatus === false) {
                        // rejected by PRM
                        notif.id = `record-workflow-rejected-${updated}`;
                        notif.title = 'Enrollment Rejected!';
                        notif.description = (
                            <Box>
                                Enrollment <b>{updated}</b> was rejected due to{' '}
                                <b>{rejectReason}</b>
                            </Box>
                        );
                        notif.status = NotificationStatus.WARNING;

                        // we need to change review status
                        updatedFields.reviewStatus = STATUS.REJECTED;
                        updatedFields.reviewReason = rejectReason;
                    } else {
                        // either redo or escalated
                        notif.id = `record-workflow-${reviewStatus.toLowerCase()}-${updated}`;
                        notif.title = `Enrollment ${reviewStatus}!`;
                        notif.description = (
                            <Box>
                                Enrollment <b>{updated}</b> was set to{' '}
                                {reviewStatus.toLowerCase()}
                            </Box>
                        );
                        notif.status = NotificationStatus.WARNING;
                    }

                    updatedEnrollment.set({
                        ...notif,
                    });
                    updateEnrollmentDetailFields(updated);
                }
            },
            onError: error => {
                let notif = {
                    id: '',
                    title: '',
                    description: <></>,
                    status: '',
                };

                updatedFields = {};
                const { statusCode, message } = parseErrorResponse(error);

                if (statusCode === 420) {
                    notif.id = `record-workflow-incomplete`;
                    notif.title = `Enrollment Incomplete!`;
                    notif.description = (
                        <Box>
                            There is an error updating your enrollment.
                            <br />
                            <b>{message}</b>
                        </Box>
                    );
                    notif.status = NotificationStatus.ERROR;
                } else if (statusCode === 422) {
                    // NOTE: for special errors from Core API
                    let msgLines = [];
                    if (message) {
                        msgLines = message.split('\n');
                    }
                    notif.id = `record-workflow-incomplete`;
                    notif.title = ``;
                    notif.description = (
                        <Box>
                            {msgLines.map(line => (
                                <>
                                    {line}
                                    <br />
                                </>
                            ))}
                        </Box>
                    );
                    notif.status = NotificationStatus.ERROR;
                } else {
                    // NOTE: for other error without canned messages
                    notif.id = 'record-workflow-error';
                    notif.title = 'Enrollment Error';
                    notif.description = (
                        <Box>
                            {message ||
                                `An error occurred while updating the enrollment status.`}
                        </Box>
                    );
                    notif.status = NotificationStatus.ERROR;
                }

                updatedEnrollment.set({
                    ...notif,
                });
            },
        }
    );

    const onUpdateField = (id, fieldKey, fieldValue) => {
        const details: any = { [`${fieldKey}`]: fieldValue };

        if (fieldKey === 'imei') {
            let updatedIMEI = listUpdatedIMEI;
            if (!updatedIMEI) {
                updatedIMEI = {};
            }
            updatedIMEI[id] = fieldValue;
            setListUpdatedIMEI(updatedIMEI);
        }

        details.reviewerWorkerSid = accountInfo?.sid;
        updatedFields = details;
        updateField({ recordId: id, details: details });
    };

    const onVerifiedImei = data => {
        const { imei, detectedDevice, recordId } = data;

        // we need to update
        if (detectedDevice) {
            updatedFields = { detectedDevice, imei };
            updateEnrollmentDetailFields(recordId);
            // just to force re-render
            forceUpdate();
        }
    };

    const onSubmitReview = ({ id, workflow, checklist, notes = '', reason = '' }) => {
        const details: any = {
            reviewerChecklist: checklist,
            reviewStatus: workflow,
            reviewer: accountInfo?.name,
            reviewerWorkerSid: accountInfo?.sid,
            reviewNotes: notes,
            reviewReason: reason,
        };

        updatedFields = details;
        setUpdatedRecordId(id);
        updateWorkflowStatus({ recordId: id, details: details });
    };

    const onPhotosFetched = photos => {
        let scores: Partial<IPhotosScoreGtm> = {};

        photos.map(photo => {
            const { dataPoints, imageType } = photo;

            switch (imageType) {
                case 'front':
                    scores.ConfidenceScoreFront = dataPoints?.confidenceScore;
                    scores.SharpnessScoreFront = dataPoints?.sharpnessScore;
                    scores.ConfidenceThresholdFront = dataPoints?.confidenceThreshold;
                    scores.SharpnessThresholdFront = dataPoints?.sharpnessThreshold;
                    break;
                case 'back':
                    scores.ConfidenceScoreBack = dataPoints?.confidenceScore;
                    scores.SharpnessScoreBack = dataPoints?.sharpnessScore;
                    scores.ConfidenceThresholdBack = dataPoints?.confidenceThreshold;
                    scores.SharpnessThresholdBack = dataPoints?.sharpnessThreshold;
                    break;
                case 'imei':
                    scores.ConfidenceScoreIMEI = dataPoints?.confidenceScore;
                    scores.SharpnessScoreIMEI = dataPoints?.sharpnessScore;
                    scores.ConfidenceThresholdIMEI = dataPoints?.confidenceThreshold;
                    scores.SharpnessThresholdIMEI = dataPoints?.sharpnessThreshold;
                    break;
            }
        });

        setPhotosScore(scores);
    };

    // When reviewer click assign to me
    /**
     * Notes on assign to me
     * 1. One reviewer = One assigned task at the same time
     * 2. Only user with right role can assign
     * 3. A submission cannot re-assigned, reviewer should finish it
     */
    const onAssignToMe = recordId => {
        if (userRoles.indexOf(UserRoles.REVIEWER) >= 0) {
            assignTaskToWorker({
                recordId: recordId,
                reviewerWorkerSid: accountInfo?.sid,
                reviewer: accountInfo?.name,
            });

            setUpdatedRecordId(recordId);
        } else {
            updatedEnrollment.set({
                id: `assign-task-error`,
                title: 'Assign Task Error',
                description: `Only reviewer can assign a task.`,
                status: NotificationStatus.ERROR,
            });
        }
    };

    const onUnassign = recordId => {
        unAssignTaskFromWorker({
            recordId: recordId,
            reviewerWorkerSid: accountInfo?.sid,
            reviewer: accountInfo?.name,
        });

        setUpdatedRecordId(recordId);
    };

    const onSpfUpload = recordId => {
        if (uploadedSpfFiles) {
            if (uploadedSpfFiles.indexOf(recordId) === -1) {
                let spfs = uploadedSpfFiles;
                spfs.push(recordId);
                setUploadedSpfFiles(spfs);

                updatedEnrollment.set({
                    id: 'record-spf-upload',
                    title: 'SPF Upload',
                    description: `SPF document for ${recordId} has been uploaded`,
                    status: NotificationStatus.SUCCESS,
                });

                latestUploadedSpf.set({
                    recordId: recordId,
                    status: 'success',
                });

                forceUpdate();
            }
        } else {
            let spfs = [recordId];
            setUploadedSpfFiles(spfs);

            updatedEnrollment.set({
                id: 'record-spf-upload',
                title: 'SPF Upload',
                description: `SPF document for ${recordId} has been uploaded`,
                status: NotificationStatus.SUCCESS,
            });

            latestUploadedSpf.set({
                recordId: recordId,
                status: 'success',
            });

            forceUpdate();
        }
    };

    const addGtmEvents = (enrollmentData: any[]) => {
        enrollmentData.forEach(session => {
            let otherGtmData: any = {};
            otherGtmData['OCRConsoleMakeAndModel'] = session.detectedDevice;
            otherGtmData['OCRConsoleIMEI'] = session.imei;

            otherGtmData['isOCRMakeAndModelDetected'] =
                session.detectedDevice && session.detectedDevice !== '' ? true : false;
            otherGtmData['isMakeAndModelDetected'] =
                session.detectedDevice && session.detectedDevice !== '' ? true : false;

            otherGtmData['isOCRIMEIDetected'] =
                session.imei && session.imei !== '' ? true : false;
            otherGtmData['isAllIMEIDetected'] =
                session.imei && session.imei !== '' ? true : false;
            otherGtmData['Partner'] = session?.partner;
            otherGtmData['Program'] = session?.program;

            addEvent(session.recordId, ANALYTICS_EVENTS.OCR_IMEI, otherGtmData);
        });
    };

    if (!isEnrollmentFetching) {
        if (
            enrollmentData &&
            enrollmentData.length > 0 &&
            enrollmentDetails.length === 0
        ) {
            setEnrollmentDetails(enrollmentData);
            setEnrollmentDetailsError(false);
            setSelectedCarrier(enrollmentData[0].partner ? enrollmentData[0].partner.toLowerCase() : AppConfig.defaultCarrier);
            console.log('ENROLLMENT DATA', enrollmentData);
            addGtmEvents(enrollmentData);
        }

        if (enrollmentError && enrollmentDetailsError === null) {
            setEnrollmentDetailsError(true);
        }
    }

    if (!isChecklistFetching) {
        if (checklistData && !reviewerWorkflowData) {
            setReviewerWorkflowData(checklistData);
        }
    }

    useEffect(() => {
        if (accountInfo && userRoles.length === 0) {
            setUserRoles(accountInfo.roles);
        }
    }, [userRoles, accountInfo]);

    useEffect(() => {
        if (updateTimestamp) {
            console.info('Last updated', updateTimestamp);
        }
    }, [updateTimestamp]);

    const showLoader =
        (isEnrollmentFetching && enrollmentDetails.length === 0) ||
            (isChecklistFetching && !reviewerWorkflowData)
            ? true
            : false;

    return (
        <>
            {(!primary || !secondary) && <Navigate to={AppRoutes.SEARCH} />}

            {showLoader && (
                <FullWidth>
                    <VerticalCenter
                        innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                    >
                        <Spinner variant={'pageLoader'} />
                    </VerticalCenter>
                </FullWidth>
            )}
            {!showLoader && (
                <FullWidth
                    id="enrollment-details-page"
                    backgroundColor="asuWhite.300"
                    width={'100%'}
                    minW={AppScreen.minWidth}
                    p={'30px 16px 16px 16px'}
                    mt="60px"
                    h="fit-content"
                    overflow="auto"
                >
                    <ReviewClose />
                    <BorderedBox
                        styleProps={{
                            height: 'auto',
                            backgroundColor: 'asuGray.400',
                        }}
                    >
                        <BorderedBox styleProps={{ height: 'auto' }}>
                            {enrollmentDetailsError === true && (
                                <Box marginBottom={'23px'}>
                                    <Flex direction={'row'}>
                                        <BorderedBox
                                            styleProps={{
                                                minWidth:
                                                    AppScreen.reviewPage.detailsMinWidth,
                                                marginRight: '10px',
                                                width: '100%',
                                            }}
                                        >
                                            <VerticalCenter
                                                innerProps={{
                                                    w: 'auto',
                                                    m: '0',
                                                    borderRadius: '8px',
                                                }}
                                            >
                                                There is an error while fetching selected
                                                enrollment details. Please try again
                                                later.
                                                <br />
                                                <Center>
                                                    <Button
                                                        as={'button'}
                                                        variant={'solid'}
                                                        onClick={() => {
                                                            window.location.reload();
                                                        }}
                                                    >
                                                        Retry
                                                    </Button>
                                                </Center>
                                            </VerticalCenter>
                                        </BorderedBox>
                                    </Flex>
                                </Box>
                            )}
                            {enrollmentDetailsError === false &&
                                enrollmentDetails &&
                                enrollmentDetails.length === 0 && (
                                    <Box marginBottom={'23px'}>
                                        <Flex direction={'row'}>
                                            <BorderedBox
                                                styleProps={{
                                                    minWidth:
                                                        AppScreen.reviewPage
                                                            .detailsMinWidth,
                                                    marginRight: '10px',
                                                    width: '100%',
                                                }}
                                            >
                                                <VerticalCenter
                                                    innerProps={{
                                                        w: 'auto',
                                                        m: '0',
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    No session for primary mdn{' '}
                                                    <b>{primary}</b> and secondary mdn{' '}
                                                    <b>{secondary}</b>
                                                </VerticalCenter>
                                            </BorderedBox>
                                        </Flex>
                                    </Box>
                                )}
                            {enrollmentDetails.map((details, index) => {
                                const recordId = details.recordId;
                                const detectedDevice = details.detectedDevice || '';

                                details.reviewStatus =
                                    details.reviewStatus?.toUpperCase();
                                const reviewer = details.reviewer || '';
                                const reviewStatus = details.reviewStatus
                                    ? details.reviewStatus.toLowerCase()
                                    : '';

                                return (
                                    <Box
                                        marginBottom={'23px'}
                                        className={`smartscan-record-${recordId}`}
                                        key={`session-record-${index}-${recordId}`}
                                    >
                                        <Flex direction={'row'}>
                                            <BorderedBox
                                                styleProps={{
                                                    minWidth:
                                                        AppScreen.reviewPage
                                                            .detailsMinWidth,
                                                    marginRight: '10px',
                                                    width: '100%',
                                                }}
                                            >
                                                <Tabs>
                                                    <TabList border={'none'}>
                                                        <DetailTab title="Submission Details"></DetailTab>
                                                        <DetailTab title="Session Details"></DetailTab>
                                                    </TabList>

                                                    <TabPanels>
                                                        <TabPanel>
                                                            <Flex direction={'column'}>
                                                                <SubmissionDetails
                                                                    details={details}
                                                                    onUpdate={
                                                                        onUpdateField
                                                                    }
                                                                    onVerifiedImei={
                                                                        onVerifiedImei
                                                                    }
                                                                />
                                                            </Flex>
                                                        </TabPanel>
                                                        <TabPanel>
                                                            <Flex direction={'column'}>
                                                                <SessionDetails
                                                                    details={details}
                                                                />
                                                            </Flex>
                                                        </TabPanel>
                                                    </TabPanels>
                                                </Tabs>
                                            </BorderedBox>
                                            <BorderedBox
                                                styleProps={{
                                                    minWidth:
                                                        AppScreen.reviewPage
                                                            .checklistMinWidth,
                                                    p: '16px',
                                                }}
                                            >
                                                {!isChecklistFetching && (
                                                    <ReviewerChecklist
                                                        recordId={recordId}
                                                        details={details}
                                                        reviewCompleted={
                                                            details?.reviewerChecklist
                                                        }
                                                        isUpdating={
                                                            updatedRecordId === recordId
                                                                ? isUpdatingWorkflow
                                                                : false
                                                        }
                                                        isAssigning={
                                                            updatedRecordId === recordId
                                                                ? isAssigning
                                                                : false
                                                        }
                                                        isUnAssigning={
                                                            updatedRecordId === recordId
                                                                ? isUnAssigning
                                                                : false
                                                        }
                                                        hasSpf={
                                                            uploadedSpfFiles &&
                                                                uploadedSpfFiles.indexOf(
                                                                    recordId
                                                                ) > -1
                                                                ? true
                                                                : false
                                                        }
                                                        reviewerChecklist={
                                                            reviewerWorkflowData?.checklist
                                                        }
                                                        reviewerReasons={{
                                                            redo: reviewerWorkflowData?.redoReasons,
                                                            reject: reviewerWorkflowData?.rejectReasons,
                                                            escalate:
                                                                reviewerWorkflowData?.escalateReasons,
                                                        }}
                                                        onSpfUpload={onSpfUpload}
                                                        onSpfCancel={recordId => {
                                                            console.info(
                                                                'Cancelled SPF upload',
                                                                recordId
                                                            );
                                                        }}
                                                        onSubmit={onSubmitReview}
                                                        onAssignToMe={onAssignToMe}
                                                        onUnassign={onUnassign}
                                                    />
                                                )}
                                            </BorderedBox>
                                        </Flex>
                                        {index > 0 && (
                                            <Button
                                                w={'100%'}
                                                fontSize={'18px'}
                                                fontWeight={700}
                                                py={'10px'}
                                                borderRadius={'10px'}
                                                rightIcon={
                                                    showRecordSection === index ? (
                                                        <ChevronUpIcon />
                                                    ) : (
                                                        <ChevronDownIcon />
                                                    )
                                                }
                                                onClick={() => {
                                                    if (showRecordSection !== index) {
                                                        setShowRecordSection(index);
                                                    } else {
                                                        setShowRecordSection(0);
                                                    }
                                                }}
                                            >
                                                {showRecordSection === index
                                                    ? 'View Less'
                                                    : 'View More'}
                                            </Button>
                                        )}

                                        {(index === 0 || showRecordSection === index) && (
                                            <Flex direction={'column'} marginTop={'10px'}>
                                                {isFeatureEnabled(
                                                    AppFeatures.FLOATING_NOTES
                                                ) && <ReviewNotes />}
                                                <ReviewVideos
                                                    recordId={recordId}
                                                    reviewer={reviewer}
                                                    reviewStatus={reviewStatus}
                                                />
                                                <ReviewPhotos
                                                    recordId={recordId}
                                                    detectedDevice={detectedDevice}
                                                    reviewer={reviewer}
                                                    reviewStatus={reviewStatus}
                                                    onPhotosFetched={onPhotosFetched}
                                                />
                                                {isFeatureEnabled(
                                                    AppFeatures.REVIEW_DOCUMENTS
                                                ) && (
                                                        <ReviewDocuments
                                                            recordId={recordId}
                                                        />
                                                    )}
                                            </Flex>
                                        )}
                                    </Box>
                                );
                            })}
                        </BorderedBox>
                    </BorderedBox>
                </FullWidth>
            )}
        </>
    );
};
export default EnrollmentDetailsPage;
