import { entity, persistence } from 'simpler-state';
import { AppConfig } from '../config/config';

const ssoSessionKeyName = 'ssoSession';

function getSsoSession(): any {
    const window = global?.window;
    return window && window.sessionStorage && window.sessionStorage[ssoSessionKeyName]
        ? window.sessionStorage[ssoSessionKeyName]
        : null;
}

export const ssoSession = entity(getSsoSession(), [
    persistence(ssoSessionKeyName, { storage: 'session' }),
]);

export const account = entity(null, [
    persistence('account', {
        storage: 'session',
    }),
]);

export const activeRegion = entity(AppConfig.defaultRegion, [
    persistence('activeRegion'),
]);

export const activeCarrier = entity(AppConfig.defaultCarrier, [
    persistence('activeCarrier'),
]);

export const apiToken = entity(null, [
    persistence('apiToken', {
        storage: 'session',
    }),
]);

export const getApiToken = () => {
    return sessionStorage && sessionStorage.apiToken ? sessionStorage.apiToken : null;
};

// export const account = entity(null, [
//   persistence('account', {
//     storage: 'session',
//     serializeFn: encryptData,
//     deserializeFn: decryptData,
//   }),
// ]);
