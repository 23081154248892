import * as React from 'react';

const CircleCrossSvg = props => (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={10} cy={10.5} r={10} fill="#D91B1B" />
        <path
            d="M6.433 13.469C10.139 9.795 11.27 8.774 13.9 6.44"
            stroke="#fff"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.901 13.469C10.195 9.795 9.063 8.774 6.434 6.44"
            stroke="#fff"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CircleCrossSvg;
