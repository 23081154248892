import { Box } from '@chakra-ui/react';
import React from 'react';
import { AppScreen } from '../../../config/config';

const PhotoFloatingLayer: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <Box display="block" position="relative">
            <Box
                position="absolute"
                display="block"
                w={AppScreen.reviewPage.mediaWidth}
                h={AppScreen.reviewPage.mediaHeight}
                css={{
                    '> svg': {
                        position: 'relative',
                        top: '-1px',
                        left: 0,
                        float: 'left',
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default PhotoFloatingLayer;
