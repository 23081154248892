import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Link, Spacer, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import PdfLogo from '../../assets/images/pdf-logo.png';
import { AppScreen } from '../../config/config';
import { latestUploadedSpf } from '../../entities/enrollments';
import { fetchEnrollmentFiles } from '../../services/apiService';
import BorderedBox from '../layouts/BorderedBox';
import VerticalCenter from '../layouts/VerticalCenter';

const ReviewDocuments: React.FC<{
    recordId: string;
}> = ({ recordId }) => {
    const [scrollIndex, setScrollIndex] = useState(0);
    const [documents, setDocuments] = useState<any>(null);
    const latestUpload = latestUploadedSpf.use();

    const enableFetch = () => {
        let enabled = false;
        if (documents === null) {
            enabled = true;
        }

        if (
            latestUpload &&
            latestUpload.recordId === recordId &&
            latestUpload.status === 'success'
        ) {
            enabled = true;
        }

        return enabled;
    };

    let {
        data: enrollmentDocs,
        isFetching: isFetchingDocs,
        refetch,
    } = useQuery(
        [recordId],
        () => {
            return fetchEnrollmentFiles({ recordId, fileType: 'spf' });
        },
        {
            enabled: enableFetch(),
        }
    );

    if (!isFetchingDocs && enrollmentDocs && documents === null) {
        setDocuments(enrollmentDocs);
    } else if (
        !isFetchingDocs &&
        enrollmentDocs &&
        latestUpload &&
        latestUpload.recordId === recordId &&
        latestUpload.status === 'success'
    ) {
        setDocuments(enrollmentDocs);
        latestUploadedSpf.set(null);
    }

    useEffect(() => {
        if (
            latestUpload &&
            latestUpload.recordId === recordId &&
            latestUpload.status === 'success' &&
            !isFetchingDocs
        ) {
            setTimeout(() => {
                refetch();
            }, 3000);
        }
    }, [latestUpload, recordId, refetch, isFetchingDocs]);

    return (
        <BorderedBox styleProps={{ p: 0 }}>
            <Flex direction={'row'} align={'right'} alignItems={'center'}>
                <Spacer />
            </Flex>
            <Flex>
                <Flex
                    id="docs-scroll-container"
                    maxW="calc(100% - 200px)"
                    minHeight="200px"
                    direction="row"
                    m="24px"
                    overscrollBehaviorX="contain"
                    scrollSnapType="x mandatory"
                    overflowX="scroll"
                    css={{
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {isFetchingDocs && (
                        <VerticalCenter
                            innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                        >
                            <Spinner variant={'pageLoader'} />
                        </VerticalCenter>
                    )}
                    {!isFetchingDocs &&
                        documents &&
                        documents.length !== 0 &&
                        documents.map(doc => {
                            if (doc) {
                                return (
                                    <Box
                                        key={`doc-${doc.fileId}`}
                                        w={AppScreen.reviewPage.mediaWidth}
                                        alignItems="center"
                                        marginRight="22px"
                                        scrollSnapAlign="start"
                                    >
                                        <Box
                                            w={'360px'}
                                            h={'640px'}
                                            backgroundColor={'asuGray.10'}
                                            borderRadius={'8px'}
                                            textAlign={'center'}
                                            position={'relative'}
                                            marginRight="22px"
                                        >
                                            <VerticalCenter
                                                innerProps={{
                                                    w: 'auto',
                                                    m: '0',
                                                    textAlign: 'center',
                                                }}
                                                h={'100%'}
                                            >
                                                <img
                                                    src={PdfLogo}
                                                    width={'60px'}
                                                    height={'61px'}
                                                    style={{ margin: '0 auto' }}
                                                    alt={''}
                                                />
                                                {doc && doc.url && (
                                                    <Link
                                                        href={doc.url}
                                                        download="spf_document.pdf"
                                                    >
                                                        SPF Document
                                                    </Link>
                                                )}
                                            </VerticalCenter>
                                        </Box>
                                    </Box>
                                );
                            }
                        })}
                </Flex>
                {!isFetchingDocs && enrollmentDocs && enrollmentDocs.length > 4 && (
                    <Flex alignItems="center">
                        <Button
                            variant="scrollSnapButton"
                            onClick={() => {
                                if (scrollIndex >= 1) {
                                    document
                                        .getElementById('docs-scroll-container')
                                        ?.scrollTo({
                                            left: (scrollIndex - 1) * 360,
                                            behavior: 'smooth',
                                        });
                                    setScrollIndex(scrollIndex - 1);
                                }
                            }}
                        >
                            <ChevronLeftIcon boxSize={10} />
                        </Button>
                        <Button
                            variant="scrollSnapButton"
                            onClick={() => {
                                if (
                                    enrollmentDocs &&
                                    scrollIndex < enrollmentDocs.length - 3
                                ) {
                                    document
                                        .getElementById('docs-scroll-container')
                                        ?.scrollTo({
                                            left: (scrollIndex + 1) * 360,
                                            behavior: 'smooth',
                                        });
                                    setScrollIndex(scrollIndex + 1);
                                }
                            }}
                        >
                            <ChevronRightIcon boxSize={10} />
                        </Button>
                    </Flex>
                )}
            </Flex>
        </BorderedBox>
    );
};

export default ReviewDocuments;
