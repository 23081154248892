import React, { Dispatch, SetStateAction } from 'react';
import { Box, HStack, Select, Spacer, Text } from '@chakra-ui/react';
import { ISearchTableRow } from '../../pages/SearchPage';
import { prevPageTokenStore } from '../../entities/enrollments';

interface TablePaginationProps {
    inputData: ISearchTableRow[];
    hasNextBtn: boolean;
    pageNum: number;
    rowLimit: number;
    setRowLimit: Dispatch<SetStateAction<number>>;
    setPageToken: any;
    setPageNum: any;
}

const TablePagination: React.FC<TablePaginationProps> = ({
    inputData,
    hasNextBtn,
    pageNum,
    rowLimit,
    setRowLimit,
    setPageToken,
    setPageNum,
}) => {
    const prevPageToken = prevPageTokenStore.use();

    const onNextPageClick = () => {
        setPageNum(pageNum + 1);
        setPageToken(prevPageToken[pageNum + 1]);
    };

    const onPrevPageClick = () => {
        setPageToken(prevPageToken[pageNum - 1]);
        setPageNum(pageNum - 1);
    };

    return (
        <HStack h="76px" p="0 80px">
            <Spacer />
            {pageNum > 1 && (
                <Box
                    as="button"
                    borderRadius="40px"
                    border="2px solid #111"
                    bgColor="white"
                    color="black"
                    fontSize="16px"
                    fontWeight="700"
                    w="115px"
                    h="44px"
                    _hover={{
                        bgColor: 'black',
                        color: 'white',
                    }}
                    onClick={() => onPrevPageClick()}
                >
                    {'< Previous'}
                </Box>
            )}
            <Box
                as="button"
                borderRadius="50%"
                bgColor="white"
                color="black"
                w="44px"
                h="44px"
                disabled
            >
                <Text as="b">{pageNum}</Text>
            </Box>
            {hasNextBtn && (
                <Box
                    as="button"
                    borderRadius="40px"
                    border="2px solid #111"
                    bgColor="white"
                    color="black"
                    fontSize="16px"
                    fontWeight="700"
                    w="86px"
                    h="44px"
                    _hover={{
                        bgColor: 'black',
                        color: 'white',
                    }}
                    onClick={() => onNextPageClick()}
                    disabled={!hasNextBtn}
                >
                    {'Next >'}
                </Box>
            )}
            {inputData.length !== 0 && (
                <>
                    <Text mr="32px">Rows per page</Text>
                    <Select
                        w="72px"
                        h="48px"
                        value={rowLimit}
                        onChange={e => {
                            setRowLimit(Number(e.target.value));
                        }}
                    >
                        {[2, 5, 10].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Select>
                </>
            )}
        </HStack>
    );
};
export default TablePagination;
