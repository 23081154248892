import { Text } from '@chakra-ui/react';
import React from 'react';

const DetailText: React.FC<{
    value?: string;
}> = ({ value }) => {
    return <Text variant="fieldValue">{value || '-'}</Text>;
};

export default DetailText;
