import { entity, persistence } from 'simpler-state';

export const twilioWorkspaceToken = entity(null, [
    persistence('twilioWorkspaceToken', {
        storage: 'session',
    }),
]);

export const twilioWorkerToken = entity(null, [
    persistence('twilioWorkerToken', {
        storage: 'session',
    }),
]);

export const workerStatus: any = entity(null, [persistence('workerStatus')]);

export const workerActivityList = entity(null, [persistence('workerActivityList')]);

export const twilioNotification: any = entity(null);
