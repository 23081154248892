/* eslint-disable no-console */
import { Box } from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../config/config';

import { account, ssoSession } from '../../entities/session';
import Header from '../Header';
import TwilioShell from './TwilioShell';

interface Props {
    route: string;
    children?: ReactNode;
}

const ProtectedtPage: React.FC<Props> = ({ children, route }) => {
    const ssoSess = ssoSession.use();
    const accountData = account.use();
    const authorized = ssoSess && accountData;

    const [statusUpdate, setStatusUpdate] = useState();

    const onStatusUpdate = newStatus => {
        setStatusUpdate(newStatus);
    };

    return (
        <Box h="100%" w={'100%'} id={'protected-page'}>
            {!authorized && <Navigate to={AppRoutes.INDEX} replace />}
            {authorized && <Header route={route} statusUpdate={statusUpdate} />}
            {authorized && (
                <TwilioShell onStatusUpdate={onStatusUpdate}>{children}</TwilioShell>
            )}
        </Box>
    );
};

export default ProtectedtPage;
