import * as React from 'react';

const ApprovedMediaSvg = () => (
    <svg
        width="51"
        height="51"
        viewBox="0 0 51 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.427734" y="0.724609" width="50" height="50" rx="10" fill="#37E7A7" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.0277 25.7246C39.0277 33.2357 32.9388 39.3246 25.4277 39.3246C17.9167 39.3246 11.8277 33.2357 11.8277 25.7246C11.8277 18.2135 17.9167 12.1246 25.4277 12.1246C32.9388 12.1246 39.0277 18.2135 39.0277 25.7246ZM42.4277 25.7246C42.4277 35.1135 34.8166 42.7246 25.4277 42.7246C16.0389 42.7246 8.42773 35.1135 8.42773 25.7246C8.42773 16.3358 16.0389 8.72461 25.4277 8.72461C34.8166 8.72461 42.4277 16.3358 42.4277 25.7246ZM23.2412 33.6474L35.2042 20.0474L32.6513 17.8018L21.8744 30.0535L18.1187 26.3661L15.7367 28.7922L20.7738 33.7377L22.0552 34.9958L23.2412 33.6474Z"
            fill="white"
        />
    </svg>
);

export default ApprovedMediaSvg;
