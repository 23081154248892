import * as React from 'react';

const AlertNoSvg = (props: any) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || 'none'}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.36569 4.23431L8 6.86863L10.6343 4.23431L11.7657 5.36569L9.13137 8L11.7657 10.6343L10.6343 11.7657L8 9.13137L5.36569 11.7657L4.23431 10.6343L6.86863 8L4.23431 5.36569L5.36569 4.23431Z"
      fill="#DB1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8C1.6 4.46538 4.46538 1.6 8 1.6C11.5346 1.6 14.4 4.46538 14.4 8Z"
      fill={props.fill || '#DB1B1B'}
    />
  </svg>
);

export default AlertNoSvg;
