import React from 'react';
import { Navigate } from 'react-router-dom';

import { AppRoutes } from '../config/config';
import { ssoSession } from '../entities/session';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoginPage: React.FC = ({ ...props }) => {
    ssoSession.set(null);

    return <Navigate to={AppRoutes.INDEX} replace />;
};

export default LoginPage;
