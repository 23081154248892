import * as React from 'react';

const DropdownSvg = () => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 10.5L12 7.29435L11.0088 6.5L8 8.91129L4.99119 6.5L4 7.29435L8 10.5Z"
            fill="#111111"
        />
    </svg>
);

export default DropdownSvg;
