import { Text } from '@chakra-ui/react';
import React from 'react';

const DetailEmail: React.FC<{
    value?: string;
}> = ({ value }) => {
    return (
        <Text variant="fieldValue" style={{ textTransform: 'none' }}>
            {value || '-'}
        </Text>
    );
};

export default DetailEmail;
