import React from 'react';
import { Grid, GridItem, IconButton } from '@chakra-ui/react';
import WorkflowApproveSvg from '../../assets/WorkflowApproveSvg';
import WorkflowEscalate from '../../assets/WorkflowEscalate';
import WorkflowRedoSvg from '../../assets/WorkflowRedoSvg';
import WorkflowRejectSvg from '../../assets/WorkflowRejectSvg';
import { AppFeatures, SCREEN_STATE } from '../../config/config';
import { isFeatureEnabled } from '../../lib/helpers';
import { account } from '../../entities/session';
import { IEnrollmentDetails } from '../../config/interface';

const WorkflowActions: React.FC<{
    details: IEnrollmentDetails;
    onWorkflowChange?: Function;
    isApprovalValidated: boolean

}> = ({ details, onWorkflowChange = () => { }, isApprovalValidated }) => {
    const accountInfo: any = account.use();
    const { reviewer } = details;

    return (
        <>
            {reviewer && reviewer === accountInfo?.name && (
                <Grid
                    height={'164px'}
                    templateRows={'repeat(2, 1fr)'}
                    templateColumns={'repeat(2, 1fr)'}
                    gap={2}
                    marginTop={'30px'}
                >
                    <GridItem m={'0 10px 5px 0'}>
                        <IconButton
                            variant={'transparent'}
                            aria-label="Approve"
                            icon={<WorkflowApproveSvg />}
                            w={'56px'}
                            h={'70px'}
                            disabled={!isFeatureEnabled(AppFeatures.APPROVED) || !isApprovalValidated}
                            onClick={() => {
                                onWorkflowChange(SCREEN_STATE.APPROVED);
                            }}
                        ></IconButton>
                    </GridItem>
                    <GridItem m={'0 10px 5px 0'}>
                        <IconButton
                            aria-label="Reject"
                            variant={'transparent'}
                            icon={<WorkflowRejectSvg />}
                            w={'56px'}
                            h={'70px'}
                            disabled={!isFeatureEnabled(AppFeatures.REJECTED)}
                            onClick={() => {
                                onWorkflowChange(SCREEN_STATE.REJECTED);
                            }}
                        ></IconButton>
                    </GridItem>
                    <GridItem m={'0 10px 5px 0'}>
                        <IconButton
                            variant={'transparent'}
                            aria-label="Redo"
                            icon={<WorkflowRedoSvg />}
                            w={'56px'}
                            h={'70px'}
                            disabled={!isFeatureEnabled(AppFeatures.REDO)}
                            onClick={() => {
                                onWorkflowChange(SCREEN_STATE.REDO);
                            }}
                        ></IconButton>
                    </GridItem>
                    <GridItem m={'0 10px 5px 0'}>
                        <IconButton
                            variant={'transparent'}
                            aria-label="Escalate"
                            icon={<WorkflowEscalate />}
                            w={'56px'}
                            h={'70px'}
                            disabled={!isFeatureEnabled(AppFeatures.ESCALATED)}
                            onClick={() => {
                                onWorkflowChange(SCREEN_STATE.ESCALATED);
                            }}
                        ></IconButton>
                    </GridItem>
                </Grid>
            )}
        </>
    );
};

export default WorkflowActions;
