import { Spinner } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import VerticalCenter from '../components/layouts/VerticalCenter';

import { AppRoutes } from '../config/config';
import { account, apiToken, ssoSession } from '../entities/session';
import {
    twilioWorkerToken,
    twilioWorkspaceToken,
    workerActivityList,
    workerStatus,
} from '../entities/twilio';
import useAmplify from '../hooks/useAmplify';

const LogoutPage: React.FC = () => {
    const amplify = useAmplify();
    const [loggedOut, setLoggedOut] = useState(false);

    const doLogout = useCallback(async () => {
        amplify.configure({
            domainApp: global.window.location.origin,
        });

        ssoSession.set(null);
        account.set(null);
        twilioWorkspaceToken.set(null);
        twilioWorkerToken.set(null);
        workerStatus.set(null);
        workerActivityList.set(null);
        apiToken.set(null);
        await amplify.logout();
        setLoggedOut(true);
    }, [amplify]);

    useEffect(() => {
        doLogout();
    }, [doLogout]);

    return loggedOut === true ? (
        <Navigate to={AppRoutes.INDEX} replace />
    ) : (
        <VerticalCenter innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}>
            <Spinner variant={'pageLoader'} />
        </VerticalCenter>
    );
};

export default LogoutPage;
