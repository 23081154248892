import * as React from 'react';

const FmipRefreshSvg = props => (
    <svg width={24} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m20.91 5.94-1.53 4.824-4.055-2.836"
            stroke="#8223D2"
            strokeWidth={1.4}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 20.328C8.393 21.562 3.615 18.67 2.33 13.87 1.042 9.069 3.734 4.176 8.342 2.94c4.607-1.234 9.385 1.657 10.671 6.458l.366 1.367"
            stroke="#8223D2"
            strokeWidth={1.4}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default FmipRefreshSvg;
