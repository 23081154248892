import { Box } from '@chakra-ui/react';
import React from 'react';

const DetailBoolean: React.FC<{
    value?: string;
}> = ({ value }) => {
    return (
        <Box
            borderRadius={'50%'}
            backgroundColor={value ? 'red' : 'green'}
            w={'16px'}
            h={'16px'}
        ></Box>
    );
};

export default DetailBoolean;
