import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

const FullWidth: React.FC<BoxProps> = ({ children, ...props }) => (
    <Box pos="absolute" w="100vw" left={`calc(-50vw + 50%)`} {...props}>
        {children}
    </Box>
);

export default FullWidth;
