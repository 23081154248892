import { Text } from '@chakra-ui/react';
import React from 'react';
import { parseDateTime } from '../../../lib/helpers';

const DetailDateTime: React.FC<{
    value?: string;
    props?: any;
}> = ({ value = '', props = {} }) => {
    return (
        <Text variant="fieldValue" {...props}>
            {value ? parseDateTime(value, true) : '-'}
        </Text>
    );
};

export default DetailDateTime;
