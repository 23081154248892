import React from 'react';
import { Badge, Text } from '@chakra-ui/react';
import AlertYesSvg from '../../assets/AlertYesSvg';
import AlertNoSvg from '../../assets/AlertNoSvg';
import AlertMaybeSvg from '../../assets/AlertMaybeSvg';

interface StatusChipProps {
    status: string;
    fontSize?: string;
    customText?: string;
}

const StatusChip: React.FC<StatusChipProps> = ({
    status,
    fontSize = '16px',
    customText = '',
}) => {
    const chipStyles = {
        COMPLETE: {
            colorScheme: 'green',
            borderColor: 'asuGreen.400',
            backgroundColor: 'asuGreen.100',
            color: 'asuGreen.400',
        },
        APPROVED: {
            colorScheme: 'green',
            borderColor: 'asuGreen.400',
            backgroundColor: 'asuGreen.100',
            color: 'asuGreen.400',
        },
        CLOSED: {
            colorScheme: 'green',
            borderColor: 'asuGreen.400',
            backgroundColor: 'asuGreen.100',
            color: 'asuGreen.400',
        },
        INCOMPLETE: {
            colorScheme: 'yellow',
            borderColor: 'asuYellow.200',
            backgroundColor: 'asuYellow.100',
            color: 'asuYellow.300',
        },
        REJECTED: {
            colorScheme: 'red',
            borderColor: 'asuRed.500',
            backgroundColor: 'asuRed.50',
            color: 'asuRed.500',
        },
        AUTO_CLOSED: {
            colorScheme: 'red',
            borderColor: 'asuRed.500',
            backgroundColor: 'asuRed.50',
            color: 'asuRed.500',
        },
        PENDING: {
            colorScheme: 'yellow',
            borderColor: 'asuYellow.200',
            backgroundColor: 'asuYellow.100',
            color: 'asuYellow.300',
        },
        REDO: {
            colorScheme: 'yellow',
            borderColor: 'asuYellow.200',
            backgroundColor: 'asuYellow.100',
            color: 'asuYellow.300',
        },
        ESCALATED: {
            colorScheme: 'purple',
            borderColor: 'asuPurple.350',
            backgroundColor: 'asuPurple.250',
            color: 'asuPurple.500',
        },
        ASSIGNED: {
            colorScheme: 'yellow',
            borderColor: 'asuYellow.200',
            backgroundColor: 'asuYellow.100',
            color: 'asuYellow.300',
        },
        UNDEFINED: {
            colorScheme: 'gray',
            borderColor: 'asuGray.400',
            backgroundColor: 'asuGray.100',
            color: 'asuGray.400',
        },
    };

    const icons = {
        COMPLETE: <AlertYesSvg />,
        INCOMPLETE: <AlertMaybeSvg />,
        APPROVED: <AlertYesSvg />,
        CLOSED: <AlertYesSvg />,
        REJECTED: <AlertNoSvg />,
        AUTO_CLOSED: <AlertNoSvg />,
        PENDING: <AlertMaybeSvg />,
        REDO: <AlertMaybeSvg />,
        ESCALATED: <AlertMaybeSvg fill="asuPurple.500" />,
        ASSIGNED: <AlertMaybeSvg />,
        UNDEFINED: <AlertNoSvg />,
    };

    status = status.toUpperCase();

    return (
        <Badge
            variant="outline"
            colorScheme={
                chipStyles[status]?.colorScheme || chipStyles['UNDEFINED'].colorScheme
            }
            color={chipStyles[status]?.color || chipStyles['UNDEFINED'].color}
            backgroundColor={
                chipStyles[status]?.backgroundColor ||
                chipStyles['UNDEFINED'].backgroundColor
            }
            borderColor={
                chipStyles[status]?.borderColor || chipStyles['UNDEFINED'].borderColor
            }
        >
            {icons[status]}
            <Text ml="8px" fontWeight="700" fontSize={fontSize}>
                {customText || status.replaceAll('_', ' ')}
            </Text>
        </Badge>
    );
};
export default StatusChip;
