import { extendTheme } from '@chakra-ui/react';
import { AppScreen } from './config/config';

const theme = extendTheme({
    styles: {
        global: {
            html: {
                height: '100vh',
            },
            body: {
                bg: 'asuPurple.100',
                height: '100%',
                overflow: 'auto',
            },
            th: {
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '124%',
                color: 'asuGray.300',
                bgColor: 'asuWhite.100',
                textAlign: 'left',
                boxShadow: '0px 1px 0px #E6E6E6',
            },
        },
    },
    fonts: {
        heading: 'ApercuPro, sans-serif',
        body: 'ApercuPro, sans-serif',
    },
    colors: {
        asuPurple: {
            100: '#F7F1FE',
            200: '#F3E9FB',
            250: '#F3DAFF',
            300: '#E9D7F9',
            350: '#CB5AFF',
            400: '#8C2DDC',
            500: '#8223D2', // main asurion
            700: '#3C0C64',
        },
        asuGreen: {
            100: '#F0FFFB',
            400: '#008662',
            500: '#00BF63',
        },
        asuBlue: {
            100: '#F0F3FF',
            300: '#D6DFFF',
            500: '#2743CC',
        },
        asuRed: {
            50: '#FDEDED',
            100: '#FAE8E8',
            500: '#DB1B1B',
        },
        asuYellow: {
            100: '#FFFDF5',
            200: '#EDB800',
            300: '#8F7000',
        },
        asuGray: {
            10: '#F7F7F7',
            20: '#A5AAAF',
            50: '#D3D3D3',
            100: '#C4C4C4',
            200: '#6E767D',
            300: '#757575',
            400: '#F0F0F5',
            500: '#D5D6DA',
            600: '#E6E6E6',
        },
        asuWhite: {
            100: '#FAFAFA',
            200: '#F2F2F2',
            300: '#FFFFFF',
        },
        asuBlack: {
            0: '#000000',
        },
    },
    components: {
        Menu: {
            variants: {
                headermenu: {
                    top: '-5px',
                    _hover: {
                        border: 'none',
                        background: 'none',
                    },
                    _active: {
                        border: 'none',
                        background: 'none',
                    },
                    _selected: {
                        border: 'none',
                        background: 'none',
                    },
                },
            },
        },
        CheckboxIcon: {
            variants: {
                multiselect: {
                    color: 'white',
                    backgroundColor: 'black',
                },
            },
        },
        IconButton: {
            variants: {
                transparent: {
                    border: 'none',
                    background: 'none',
                    _hover: {
                        border: 'none',
                        background: 'none',
                    },
                    _active: {
                        border: 'none',
                        background: 'none',
                    },
                    _selected: {
                        border: 'none',
                        background: 'none',
                    },
                },
            },
        },
        Text: {
            variants: {
                fieldLabel: {
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18.2px',
                    paddingBottom: '12px',
                    textTransform: 'uppercase',
                    // minWidth: '110px',
                    // width: 'min-content',
                },
                fieldValue: {
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '23.4px',
                    textTransform: 'capitalize',
                    // width: 'min-content',
                },
                datePickerHeader: {
                    fontSize: '24px',
                    lineHeight: '32px',
                    fontFamily: 'ApercuPro',
                },
                aiReviewLabel: {
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '130%',
                },
            },
        },
        Link: {
            variants: {
                headerNav: {
                    marginRight: '10px',
                    marginLeft: '20px',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '19.84px',
                },
            },
        },
        Spinner: {
            baseStyle: {
                color: 'asuPurple.500',
            },
            variants: {
                pageLoader: {
                    borderWidth: '5px',
                    borderBottomColor: 'asuPurple.300',
                    borderLeftColor: 'asuPurple.300',
                    width: '4rem',
                    height: '4rem',
                },
                menuLoader: {
                    borderWidth: '2px',
                    borderBottomColor: 'asuPurple.300',
                    borderLeftColor: 'asuPurple.300',
                    width: '1.5rem',
                    height: '1.5rem',
                },
                componentLoader: {
                    borderWidth: '2px',
                    borderBottomColor: 'asuPurple.300',
                    borderLeftColor: 'asuPurple.300',
                    width: '2rem',
                    height: '2rem',
                },
                checkListLoader: {
                    borderWidth: '6px',
                    borderBottomColor: 'asuPurple.300',
                    borderLeftColor: 'asuPurple.300',
                    width: '4rem',
                    height: '4rem',
                },
            },
            defaultProps: {
                colorScheme: 'asuPurple',
            },
        },
        Heading: {
            variants: {
                appName: {
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#fff',
                    marginTop: '28px',
                },
            },
        },
        Container: {
            variants: {
                header: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    color: 'white',
                    backgroundColor: 'black',
                    width: '100%',
                    maxWidth: 'unset',
                    height: 'auto',
                    padding: '0 20px 0 20px',
                },
            },
        },
        Button: {
            baseStyle: {
                minH: '56px',
                borderRadius: '8px',
            },
            variants: {
                outline: {
                    borderColor: 'asuPurple.500',
                    color: 'asuPurple.500',
                    background: 'white',
                    _active: {
                        background: 'asuPurple.500',
                        color: 'white',
                        borderColor: 'asuPurple.500',
                    },
                    _hover: {
                        // background: 'asuPurple.500',
                        // color: 'white',
                        borderColor: 'asuPurple.500',
                    },
                },
                solid: {
                    backgroundColor: 'asuPurple.500',
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '19.84px',
                },
                menubutton: {
                    backgroundColor: 'transparent',
                },
                navDatebutton: {
                    minH: '32px',
                    minW: '24px',
                    h: '32px',
                    p: '0',
                },
                scrollSnapButton: {
                    minH: '70px',
                    minW: '70px',
                },
            },
            defaultProps: {
                colorScheme: 'asuPurple',
            },
        },
        Badge: {
            variants: {
                outline: {
                    w: 'fit-content',
                    h: '32px',
                    p: '8px 12px',
                    border: '1px solid',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
                'fieldValue.COMPLETE': {
                    fontSize: '24px',
                    fontWeight: 700,
                },
            },
        },
        Skeleton: {
            variants: {
                mediaSkeleton: {
                    w: AppScreen.reviewPage.mediaWidth,
                    alignItems: 'center',
                    marginRight: '22px',
                    scrollSnapAlign: 'start',
                    fadeDuration: 0.5,
                },
            },
        },
    },
});

export default theme;
