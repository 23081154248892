import * as React from 'react';

const AlertYesSvg = (props: any) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || 'none'}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8C1.6 4.46538 4.46538 1.6 8 1.6C11.5346 1.6 14.4 4.46538 14.4 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.97105 11.7284L12.6007 5.32838L11.3993 4.27162L6.32783 10.0371L4.56047 8.30188L3.43953 9.44358L5.8099 11.7709L6.41291 12.3629L6.97105 11.7284Z"
      fill={props.fill || '#008662'}
    />
  </svg>
);

export default AlertYesSvg;
