import { Flex, Image as ChakraImg, Text } from '@chakra-ui/react';
import React from 'react';
import ExpertSvg from '../../../assets/ExpertSvg';
import { AppScreen } from '../../../config/config';
import { IRecordPhoto } from '../../../config/interface';
import PhotoFloatingLayer from './PhotoFloatingLayer';

interface ScreenshotViewerProps {
    photo: IRecordPhoto;
    recordId: string;
    idx: number;
}

const ScreenshotViewer: React.FC<ScreenshotViewerProps> = ({
    photo,
    recordId,
    idx
}) => {
    return (
        <>
            {/* Floating layer */}
            <PhotoFloatingLayer>
                <ExpertSvg />
            </PhotoFloatingLayer>

            {/* Displayed layer */}
            <Flex
                w="360px"
                height="640px"
                borderRadius="8px"
                border="1px solid"
                borderColor="#a5aaaf"
                overflow="hidden"
            >
                <ChakraImg
                    id={`photo-el-${recordId}-${idx}`}
                    src={photo.url}
                    alt={`screenshot-${idx}`}
                    h="640px"
                    maxW={AppScreen.reviewPage.mediaWidth}
                />
            </Flex>

            {/* Photo Details */}
            <Flex
                direction="row"
                alignItems="center"
                alignContent="center"
                m="10px auto"
                w="fit-content"
            >
                <Text
                    fontSize="24px"
                    fontWeight="700"
                    textAlign="center"
                >
                    {'Screenshot'}
                </Text>
            </Flex>
        </>
    );
};

export default ScreenshotViewer;
