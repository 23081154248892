import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Checkbox,
    CheckboxIconProps,
    Flex,
    FormLabel,
    Menu,
    MenuButton,
    MenuList,
    Spacer,
    Tag,
    TagLabel,
    Text,
} from '@chakra-ui/react';
import React from 'react';

const CustomCheckIcon = (props: CheckboxIconProps) => {
    const { isChecked } = props;

    return (
        isChecked ? (
            <CheckIcon
                style={{
                    backgroundColor: '#718096',
                    padding: '3px',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    width: '20px',
                    height: '20px',
                }}
            />
        ) : (
            <></>
        )
    )
};

interface IMultiSelectItems {
    value?: string;
    label?: string;
    key?: string;
    group?: string;
}

interface IMultiSelect {
    id: string;
    items: IMultiSelectItems[];
    selectedItems: any;
    error?: string;
    placeholder?: string;
    maxHeight?: string;
    onSelectItem?: Function;
}

const MultiSelect: React.FC<IMultiSelect> = ({
    id,
    items,
    error = '',
    selectedItems = null,
    placeholder = 'Select',
    maxHeight = '200px',
    onSelectItem = () => { },
}) => {
    return (
        <Menu closeOnSelect={false} id={`multiselect-${id}`} matchWidth={true}>
            <MenuButton
                as={FormLabel}
                defaultChecked={false}
                colorScheme="asuPurple"
                p={'12px 16px'}
                borderRadius={'8px'}
                borderColor={'asuGray.300'}
                borderWidth={'1px'}
            >
                <Flex direction={'row'}>
                    <Box>
                        <Text fontSize={'16px'} fontWeight={'400'} color={'asuGray.300'}>
                            {placeholder}
                        </Text>
                    </Box>
                    <Spacer />
                    <Box>
                        {selectedItems && selectedItems.length === 0 ? (
                            <ChevronUpIcon fontSize={'26px'} />
                        ) : (
                            <ChevronDownIcon fontSize={'26px'} />
                        )}
                    </Box>
                </Flex>
                <Box id={`menu-selecteditem-${id}`}>
                    {selectedItems.map((item, index) => {
                        return (
                            <Tag key={`selecteditem-${index}`} mr={'10px'} mb={'5px'}>
                                <TagLabel fontSize={'14px'} p={'5px'}>
                                    {item}
                                </TagLabel>
                            </Tag>
                        );
                    })}
                </Box>
            </MenuButton>
            {error && (
                <Box>
                    <Text color={'red'}>{error}</Text>
                </Box>
            )}
            <MenuList>
                <Flex direction={'column'} maxH={maxHeight} overflowY={'scroll'}>
                    <Checkbox value={''} style={{ display: 'none' }}></Checkbox>
                    {items.map((item, index) => {
                        const { key, label } = item;

                        return (
                            <Checkbox
                                defaultChecked={false}
                                checked={false}
                                key={`multiselect-item-${id}-${index}`}
                                value={key}
                                iconColor={'black'}
                                fontSize={'16px'}
                                fontWeight={'400'}
                                p={'8px 16px'}
                                // colorScheme={'gray'}
                                onChange={event => {
                                    onSelectItem({
                                        value: label || '',
                                        checked: event.target.checked,
                                    });
                                }}
                                icon={<CustomCheckIcon />}
                            >
                                {label}
                            </Checkbox>
                        );
                    })}
                </Flex>
            </MenuList>
        </Menu>
    );
};

export default MultiSelect;
