import * as React from 'react';

const ImageEditSvg = () => (
    <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="24.8105" cy="24.0605" r="24" fill="#111111" fillOpacity="0.9" />
        <path
            d="M18.5605 15.8105H27.3104V19.3105H30.8104L30.8113 22.8113H32.5604V18.0733L28.5477 14.0605H18.5605C17.5954 14.0605 16.8105 14.8463 16.8105 15.8105V31.5604C16.8105 32.5255 17.5954 33.3103 18.5605 33.3103H24.6855V31.5604H18.5605V15.8105Z"
            fill="white"
        />
        <path
            d="M22.9355 24.5613C23.902 24.5613 24.6855 23.7778 24.6855 22.8113C24.6855 21.8448 23.902 21.0613 22.9355 21.0613C21.969 21.0613 21.1855 21.8448 21.1855 22.8113C21.1855 23.7778 21.969 24.5613 22.9355 24.5613Z"
            fill="white"
        />
        <path
            d="M26.4354 23.6863L24.6863 27.1854L24.6855 27.1863L22.9355 25.4363L20.3105 28.9362H24.6863L24.6863 27.1854H28.1854L26.4354 23.6863Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.929 23.942L37.554 26.567C37.8961 26.9091 37.8961 27.4621 37.554 27.8042L31.429 33.9292C31.317 34.0421 31.177 34.1217 31.023 34.1602L27.5231 35.0352C27.2273 35.1078 26.9114 35.0247 26.6918 34.8042C26.4748 34.5872 26.3873 34.2713 26.4617 33.9738L27.3367 30.4738C27.3752 30.3198 27.4548 30.1781 27.5668 30.067L33.6917 23.942C34.0339 23.5999 34.5869 23.5999 34.929 23.942ZM28.5135 32.9824L30.3633 32.5204L33.0731 29.8106L31.6854 28.4229L28.9755 31.1327L28.5135 32.9824ZM32.9226 27.1856L34.3104 28.5734L35.6981 27.1856L34.3104 25.7979L32.9226 27.1856Z"
            fill="white"
        />
    </svg>
);

export default ImageEditSvg;
