import React from 'react';
import CircleCheckSvg from '../../assets/CircleCheckSvg';
import CircleCrossSvg from '../../assets/CircleCrossSvg';
import { STATUS } from '../../config/config';
import { IEnrollmentDetails } from '../../config/interface';
import Details from './Details';
import DetailBoolean from './fields/DetailBoolean';
import DetailDateTime from './fields/DetailDateTime';
import DetailFmipStatusCheck from './fields/DetailFmipStatusCheck';
import DetailImeiVerifyField from './fields/DetailImeiVerifyField';
import DetailPhone from './fields/DetailPhone';
import DetailSerialNumberVerifyField from './fields/DetailSerialNumberVerifyField';
import DetailStatusChip from './fields/DetailStatusChip';
import DetailText from './fields/DetailText';
import DetailTextWithIcon from './fields/DetailTextWithIcon';

const SubmissionDetails: React.FC<{
    details: IEnrollmentDetails;
    onUpdate?: Function;
    onVerifiedImei?: Function;
}> = ({ details, onUpdate = () => { } }) => {
    let fieldSets = [
        {
            id: 'set1',
            fields: [
                {
                    label: 'Submission Status',
                    key: 'submissionStatus',
                    render: value => <DetailStatusChip value={value} />,
                },
                {
                    label: 'Date & Time',
                    key: 'dateTime',
                    render: value => <DetailDateTime value={value} />,
                },
                {
                    label: 'Method & Duration',
                    key: 'methodDuration',
                    render: value => {
                        return <DetailText value={value.trim()} />;
                    },
                },
                {
                    label: 'Phone to Enroll No.',
                    key: 'secondary',
                    render: value => {
                        return <DetailPhone value={value.trim()} />;
                    },
                },
                {
                    label: 'Partner & Plan',
                    key: 'partnerPlan',
                    render: value => {
                        return <DetailText value={value?.toLowerCase()} />;
                    },
                },
                {
                    label: 'Device Type',
                    key: 'deviceType',
                    render: value => {
                        return <DetailText value={value?.toLowerCase()} />;
                    },
                },
            ],
        },
        {
            id: 'set2',
            fields: [
                {
                    label: 'IMEI',
                    key: 'imei',
                    render: value => (
                        <DetailImeiVerifyField
                            recordId={details.recordId}
                            status={details.reviewStatus?.toUpperCase() || STATUS.PENDING}
                            reviewer={details.reviewer || ''}
                            imei={value}
                            onUpdate={onUpdate}
                        />
                    ),
                },
                {
                    label: 'Serial Number',
                    key: 'serialNumber',
                    render: value => (
                        <DetailSerialNumberVerifyField
                            recordId={details.recordId}
                            reviewer={details.reviewer || ''}
                            status={details.reviewStatus?.toUpperCase() || STATUS.PENDING}
                            serialNumber={value}
                            onUpdate={onUpdate}
                        />
                    ),
                },
                {
                    label: 'Enrolled Device',
                    key: 'enrolledDevice',
                    render: value => <DetailText value={value} />,
                },
                {
                    label: 'Detected Device',
                    render: details => {
                        const { detectedDevice: value, detectedDeviceMismatch } = details;
                        const icon = detectedDeviceMismatch ? (
                            <CircleCrossSvg />
                        ) : (
                            <CircleCheckSvg />
                        );
                        return <DetailTextWithIcon value={value} icon={icon} />;
                    },
                },
                {
                    label: 'FMIP',
                    key: 'fmip',
                    render: value => (
                        <DetailFmipStatusCheck fmip={value} details={details} />
                    ),
                },
            ],
        },
    ];

    if (details && details.make && details.make.toLowerCase().indexOf('apple') > -1) {
        fieldSets[1].fields.push({
            label: 'Lost or Stolen',
            key: 'lostFound',
            render: value => {
                return <DetailBoolean value={value} />;
            },
        });
    }

    return (
        <>
            {details && (
                <Details
                    componentId="submissionDetails"
                    details={details}
                    fieldSets={fieldSets}
                />
            )}
        </>
    );
};

export default SubmissionDetails;
