import React, { useEffect, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { Navigate } from 'react-router-dom';
import FullWidth from '../components/FullWidth';
import VerticalCenter from '../components/layouts/VerticalCenter';

import { AppRoutes } from '../config/config';
import { ssoSession } from '../entities/session';
import Login from '../components/Login';

const LandingPage: React.FC = () => {
    const [checkingCredentials, setCheckingCredentials] = useState(true);
    const [hasSession, setHasSession] = useState(false);
    const ssoSess = ssoSession.use();

    useEffect(() => {
        if (ssoSess) {
            setHasSession(true);
        }

        setCheckingCredentials(false);
    }, [ssoSess]);

    return (
        <>
            {checkingCredentials && (
                <FullWidth p={4}>
                    <VerticalCenter
                        innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}
                    >
                        <Spinner variant={'pageLoader'} />
                    </VerticalCenter>
                </FullWidth>
            )}

            {!checkingCredentials && hasSession && (
                <Navigate to={AppRoutes.SEARCH} replace />
            )}

            {!checkingCredentials && !hasSession && <Login />}
        </>
    );
};

export default LandingPage;
