import React, { useEffect } from 'react';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';

import theme from '../theme';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import SearchPage from './SearchPage';
import { AppRoutes } from '../config/config';
import AuthPage from './AuthPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import ProtectedtPage from '../components/layouts/ProtectedPage';
import EnrollmentDetailsPage from './EnrollmentDetailsPage';
import '../assets/fonts/fonts.css';
import { updatedEnrollment } from '../entities/enrollments';
import { twilioNotification } from '../entities/twilio';
import analytics from '../lib/analytics';

export const App = () => {
    const toast = useToast();
    const enrollment = updatedEnrollment.use();
    const twnotification = twilioNotification.use();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: 1,
            },
        },
    });

    useEffect(() => {
        const toastParams = {
            isClosable: true,
            duration: null, // reviewer is required to manually close toast
            position: 'bottom',
            onCloseComplete: () => {
                updatedEnrollment.set(null);
            },
        };

        if (enrollment && !toast.isActive(enrollment.id)) {
            try {
                toast({
                    ...enrollment,
                    ...toastParams,
                });
                updatedEnrollment.set(null);
            } catch (e) {
                updatedEnrollment.set(null);
            }
        }
        if (twnotification && !toast.isActive(twnotification.id)) {
            try {
                toast({
                    ...twnotification,
                    ...toastParams,
                });
                twilioNotification.set(null);
            } catch (e) {
                twilioNotification.set(null);
            }
        }
    }, [enrollment, toast, twnotification]);

    useEffect(() => {
        const tagManagerArgs: any = {
            gtmId: process.env.REACT_APP_GTM_ID,
        };

        TagManager.initialize(tagManagerArgs);

        analytics.init();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                <Routes>
                    <Route path={AppRoutes.INDEX} element={<LandingPage />} />
                    <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
                    <Route path={AppRoutes.LOGOUT} element={<LogoutPage />} />
                    <Route path={AppRoutes.AUTH} element={<AuthPage />} />
                    <Route
                        path={AppRoutes.SEARCH}
                        element={
                            <ProtectedtPage route={AppRoutes.SEARCH}>
                                <SearchPage />
                            </ProtectedtPage>
                        }
                    />
                    <Route
                        path={AppRoutes.ENROLLMENT}
                        element={
                            <ProtectedtPage route={AppRoutes.ENROLLMENT}>
                                <EnrollmentDetailsPage />
                            </ProtectedtPage>
                        }
                    >
                        <Route
                            path=":primary/:secondary"
                            element={
                                <ProtectedtPage route={AppRoutes.ENROLLMENT}>
                                    <EnrollmentDetailsPage />
                                </ProtectedtPage>
                            }
                        />
                    </Route>
                </Routes>
            </ChakraProvider>
        </QueryClientProvider>
    );
};
