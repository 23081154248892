import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Spinner, Button, IconButton } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import FmipRefreshSvg from '../../assets/FmipRefreshSvg';
import { fetchEnrollmentVideos } from '../../services/apiService';
import BorderedBox from '../layouts/BorderedBox';
import VerticalCenter from '../layouts/VerticalCenter';
import VideoPlayerSkeleton from './media-gallery/VideoPlayerSkeleton';

interface ReviewVideosProps {
    recordId: string;
    reviewer: string;
    reviewStatus: string;
}

const ReviewVideos: React.FC<ReviewVideosProps> = ({ recordId, reviewer, reviewStatus }) => {
    const [scrollIndex, setScrollIndex] = useState(0);

    const useFetchVideos: any = () => {
        return useQuery(['videos', recordId], () => fetchEnrollmentVideos(recordId));
    };

    const { data: enrollmentVideos, isFetching, error, refetch: refetchVideos } = useFetchVideos();

    const handleRetryFetchVideos = () => {
        refetchVideos();
    };

    return (
        <BorderedBox styleProps={{ p: 0 }}>
            <Flex direction="row">
                <Flex
                    id={`videos-scroll-container-${recordId}`}
                    w="calc(100% - 200px)"
                    minHeight="200px"
                    direction="row"
                    m="24px"
                    overscrollBehaviorX="contain"
                    scrollSnapType="x mandatory"
                    overflowX="auto"
                    css={{
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {isFetching && (
                        <VerticalCenter
                            innerProps={{ w: 'auto', m: 'auto', borderRadius: '8px' }}
                            h={'100%'}
                            m={'auto'}
                        >
                            <Spinner variant={'pageLoader'} />
                        </VerticalCenter>
                    )}
                    {!isFetching &&
                        !error &&
                        enrollmentVideos.length !== 0 &&
                        enrollmentVideos[0] !== null &&
                        enrollmentVideos.map((video, idx) => (
                            <VideoPlayerSkeleton
                                key={`video-skeleton-${video.title}-${idx}`}
                                video={video}
                                idx={idx}
                                recordId={recordId}
                                reviewer={reviewer}
                                reviewStatus={reviewStatus}
                            />
                        ))}
                    {!isFetching &&
                        !error &&
                        (enrollmentVideos.length === 0 ||
                            (enrollmentVideos.length > 0 && enrollmentVideos[0] === null)) && (
                            <IconButton
                                aria-label="reload videos"
                                icon={<FmipRefreshSvg />}
                                variant="transparent"
                                w="50px"
                                h="50px"
                                minH="unset"
                                minW="unset"
                                m="auto"
                                onClick={handleRetryFetchVideos}
                            />
                        )}
                </Flex>
                {!isFetching && !error && enrollmentVideos.length > 4 && (
                    <Flex alignItems="center">
                        <Button
                            variant="scrollSnapButton"
                            onClick={() => {
                                if (scrollIndex >= 1) {
                                    document
                                        .getElementById(`videos-scroll-container-${recordId}`)
                                        ?.scrollTo({
                                            left: (scrollIndex - 1) * 360,
                                            behavior: 'smooth',
                                        });
                                    setScrollIndex(scrollIndex - 1);
                                }
                            }}
                        >
                            <ChevronLeftIcon boxSize={10} />
                        </Button>
                        <Button
                            variant="scrollSnapButton"
                            onClick={() => {
                                if (scrollIndex < enrollmentVideos.length - 4) {
                                    document
                                        .getElementById(`videos-scroll-container-${recordId}`)
                                        ?.scrollTo({
                                            left: (scrollIndex + 1) * 360,
                                            behavior: 'smooth',
                                        });
                                    setScrollIndex(scrollIndex + 1);
                                }
                            }}
                        >
                            <ChevronRightIcon boxSize={10} />
                        </Button>
                    </Flex>
                )}
            </Flex>
        </BorderedBox>
    );
};

export default ReviewVideos;
