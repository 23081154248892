import { Box, IconButton, Link } from '@chakra-ui/react';
import React from 'react';
import ReviewCloseSvg from '../../assets/ReviewCloseSvg';
import { AppRoutes } from '../../config/config';

const ReviewClose: React.FC = () => {
    return (
        <Box position={'absolute'} top={'10px'} right={0} zIndex={'999'}>
            <Link variant={'headerNav'} href={AppRoutes.INDEX}>
                <IconButton
                    aria-label="Close Review"
                    icon={<ReviewCloseSvg />}
                    variant={'transparent'}
                />
            </Link>
        </Box>
    );
};

export default ReviewClose;
