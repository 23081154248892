import { AppFeatures } from '../config/config';
import { isFeatureEnabled } from '../lib/helpers';

type UseTwilioReturnValue = {
    initWorkspace: (token: string) => any;
    initWorker: (token: string) => any;
    registerEvents: (events: []) => any;
    unregisterEvents: () => any;
    workspace: any;
    worker: any;
};

export enum TWILIO_EVENT {
    READY = 'ready',
    UPDATE = 'activity.update',
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    ERROR = 'error',
    TOKEN_EXPIRED = 'token.expired',
    RESERVATION_CREATED = 'reservation.created',
    RESERVATION_ACCEPTED = 'reservation.accepted',
    RESERVATION_TIMEOUT = 'reservation.timeout',
    RESERVATION_CANCELLED = 'reservation.canceled',
    RESERVARTION_REJECTED = 'reservation.rejected',
    CAPACITY_UPDATED = 'capacity.update',
}

const useTwilio = (): UseTwilioReturnValue => {
    let workspace: any = null;
    let worker: any = null;
    let workerEvents: any = [];

    const initWorkspace = (token: string): any => {
        if (isFeatureEnabled(AppFeatures.TWILIO)) {
            workspace = new window.Twilio.TaskRouter.Workspace(token);
            return workspace;
        } else {
            return {};
        }
    };

    const initWorker = (token: string) => {
        if (isFeatureEnabled(AppFeatures.TWILIO)) {
            worker = new window.Twilio.TaskRouter.Worker(token);
            return worker;
        } else {
            return {};
        }
    };

    const registerEvents = (events: []) => {
        if (isFeatureEnabled(AppFeatures.TWILIO)) {
            workerEvents = events;
            unregisterEvents();

            events.map((e: any) => {
                worker.on(e?.eventType, e?.callback);
            });
        }
    };

    const unregisterEvents = () => {
        if (isFeatureEnabled(AppFeatures.TWILIO)) {
            workerEvents.map((e: any) => {
                worker.removeListener(e?.eventType, e?.callback);
            });
        }
    };

    return {
        initWorker,
        initWorkspace,
        registerEvents,
        unregisterEvents,
        workspace,
        worker,
    };
};

export default useTwilio;
