import { Text } from '@chakra-ui/react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';

const DetailPhone: React.FC<{
    value?: string;
}> = ({ value = '' }) => {
    let phoneNumber: any = value;
    let display = value;
    if (isValidPhoneNumber(value)) {
        phoneNumber = parsePhoneNumber(value);
        display = phoneNumber.formatInternational();
    }

    return <Text variant="fieldValue">{display}</Text>;
};

export default DetailPhone;
