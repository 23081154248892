/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Text, useOutsideClick } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { RefObject, useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import PlayButtonSvg from '../../../assets/photo-gallery/PlayButtonSvg';
import VideoCaptureSvg from '../../../assets/photo-gallery/VideoCaptureSvg';
import { AppFeatures, AppScreen } from '../../../config/config';
import { account } from '../../../entities/session';
import { convertDurationToMinuteSeconds, isFeatureEnabled } from '../../../lib/helpers';
import { fetchEnrollmentPhotos, uploadFile } from '../../../services/apiService';

interface VideoPlayerProps {
    video: any;
    idx: number;
    recordId: string;
    setVideoIsLoaded: Dispatch<SetStateAction<boolean>>;
    reviewer: string;
    reviewStatus: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video, idx, recordId, setVideoIsLoaded, reviewer, reviewStatus }) => {
    const accountInfo: any = account.use();

    const { videoType } = video;

    const displayVideoType = (videoType: string) => {
        if (videoType === 'imei') {
            return 'IMEI';
        } else if (videoType === 'imei retake') {
            return 'IMEI Retake';
        } else {
            return videoType;
        }
    };

    const [videoElement, setVideoElement] = useState<HTMLVideoElement>();
    const [showControls, setShowControls] = useState(false);
    const [showPlayBtn, setShowPlayBtn] = useState(true);
    const [showCaptureBtn, setShowCaptureBtn] = useState(false);
    const [duration, setDuration] = useState('');

    const vidRef = useRef<HTMLDivElement>();
    const vidRefCasted = vidRef as RefObject<HTMLDivElement>;

    // For refetching of photos after taking a screenshot
    const useFetchPhotos: any = () => {
        return useQuery(['photos', recordId], () => fetchEnrollmentPhotos(recordId), {
            enabled: false,
            refetchOnWindowFocus: false
        });
    };

    const {
        refetch: refetchReviewPhotos,
    } = useFetchPhotos();


    const handleVideoLoadedData = () => {
        setVideoIsLoaded(true);
    };

    const handleDurationChange = (event: any) => {
        setDuration(convertDurationToMinuteSeconds(event.srcElement.duration));
    };

    const handleVideoCapture = async () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const vidEl = document.getElementById(`video-el-${recordId}-${idx}`) as HTMLVideoElement;
        const canvasWidth = AppScreen.reviewPage.canvasWidth;
        const canvasHeight = AppScreen.reviewPage.canvasHeight;

        // Scale landscape videos to fit in portrait size canvas
        const scaleX = canvasWidth / vidEl.videoWidth;
        const scaleY = canvasHeight / vidEl.videoHeight;
        const scaleFactor = Math.min(scaleX, scaleY);

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const screenW = vidEl.videoWidth * scaleFactor;
        const screenH = vidEl.videoHeight * scaleFactor;

        // Put image in center of canvas if video is landscape
        const originX = 0;
        const originY = (0.5 * canvasHeight) - (screenH / 2);

        context?.drawImage(vidEl, originX, originY, screenW, screenH);
        const imgUrl = canvas.toDataURL();

        const img = await fetch(imgUrl);
        const blob = await img.blob();
        const arrBuffer = await blob.arrayBuffer();

        const result = await uploadFile({
            file: arrBuffer,
            recordId,
            uploadType: 'video',
            subType: 'screenshot',
            associatedFileId: video.fileId,
        });

        if (result.success) {
            refetchReviewPhotos();
        }
        if (result.error) {
            console.info(result.error);
        }
    };

    useEffect(() => {
        const curVid = document.getElementById(`video-el-${recordId}-${idx}`);
        if (curVid) {
            const castedVideo = curVid as HTMLVideoElement;
            setVideoElement(castedVideo);
            castedVideo.addEventListener('loadeddata', handleVideoLoadedData);
            castedVideo.addEventListener('loadedmetadata', event =>
                handleDurationChange(event)
            );
            castedVideo.addEventListener('pause', () => setShowCaptureBtn(true));
            castedVideo.addEventListener('play', () => setShowCaptureBtn(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            ref={vidRefCasted}
            w={AppScreen.reviewPage.mediaWidth}
            alignItems="center"
            marginRight="22px"
            scrollSnapAlign="start"
        >
            <Box display="block" position="relative">
                <Box
                    position="absolute"
                    display="block"
                    w={AppScreen.reviewPage.mediaWidth}
                    h={AppScreen.reviewPage.mediaHeight}
                >
                    {showPlayBtn && (
                        <Box
                            as="button"
                            w="fit-content"
                            h="fit-content"
                            cursor="pointer"
                            position="relative"
                            top="280px"
                            left="140px"
                            zIndex={5}
                            onClick={() => {
                                setShowControls(true);
                                setShowPlayBtn(false);
                                videoElement?.play();
                            }}
                        >
                            <PlayButtonSvg />
                        </Box>
                    )}
                    {isFeatureEnabled(AppFeatures.VIDEO_SCREENSHOT) &&
                        showCaptureBtn &&
                        reviewer === accountInfo.name &&
                        reviewStatus !== 'rejected' &&
                        reviewStatus !== 'approved' && (
                            <Box
                                as="button"
                                position="absolute"
                                top="12px"
                                right="12px"
                                zIndex={5}
                                onClick={handleVideoCapture}
                            >
                                <VideoCaptureSvg />
                            </Box>
                        )}
                </Box>
            </Box>
            <Box
                as="video"
                id={`video-el-${recordId}-${idx}`}
                controls={showControls}
                src={video.url}
                objectFit="contain"
                crossOrigin="anonymous"
                maxW={AppScreen.reviewPage.mediaWidth}
                sx={{
                    aspectRatio: '9/16',
                    borderRadius: '8px',
                }}
            />
            <Text
                marginTop="10px"
                textAlign="center"
                fontSize="24px"
                textTransform={videoType !== 'imei' || videoType !== 'imei retake' ? 'capitalize' : 'unset'}
            >
                <b>{displayVideoType(videoType)}</b>, {duration || ''}
            </Text>
        </Box>
    );
};

export default VideoPlayer;
