import { Table, Thead, Tbody, Tr, Th, Td, Flex, Text, Spinner } from '@chakra-ui/react';
import {
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { flattenTableData } from '../../lib/helpers';
import { ISearchTableRow } from '../../pages/SearchPage';

import TablePagination from './Pagination';
import { prevPageTokenStore } from '../../entities/enrollments';

interface ITable {
    tableData: any;
    errorTableData: any;
    isFetchingTableData: boolean;
    columns: any[];
    pageNum: number;
    setPageNum: any;
    setPageToken: any;
    rowLimit: number;
    setRowLimit: Dispatch<SetStateAction<number>>;
}

const ReactTable: FC<ITable> = ({
    tableData,
    errorTableData,
    isFetchingTableData,
    columns,
    pageNum,
    setPageNum,
    setPageToken,
    rowLimit,
    setRowLimit,
}) => {
    const prevPageToken = prevPageTokenStore.use();

    const [flattenedData, setFlattenedData] = useState<ISearchTableRow[]>([]);
    const [expanded, setExpanded] = React.useState<ExpandedState>({});

    const updatePageTokenStore = (nextPageToken: string) => {
        if (prevPageToken !== undefined) {
            const updatedObj = { ...prevPageToken, [pageNum + 1]: nextPageToken };
            prevPageTokenStore.set(updatedObj);
        } else {
            const initObj = { [pageNum + 1]: nextPageToken };
            prevPageTokenStore.set(initObj);
        }
    };

    useEffect(() => {
        if (tableData && tableData.data) {
            const flattenedData = flattenTableData(tableData.data);
            let updatedExpanded = Object.assign({});
            flattenedData.forEach((row, idx) => {
                if (row.subRows && row.subRows.length > 0) {
                    updatedExpanded[idx] = true;
                }
            });
            setExpanded({ ...updatedExpanded });
            setFlattenedData(flattenedData);
            updatePageTokenStore(tableData.nextPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData]);

    useEffect(() => {
        // NOTE: Reset page num if row limit changed
        setPageNum(1);
        setPageToken('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowLimit]);

    const table = useReactTable({
        data: flattenedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: row => row.subRows,
        onExpandedChange: setExpanded,
        state: {
            expanded,
        },
    });

    return (
        <>
            <Table
                styleConfig={{ 'font-variant-numeric': 'none' }}
                style={{ width: '100%', backgroundColor: 'white' }}
            >
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => {
                        return (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <Th
                                        key={header.id}
                                        padding="16px 18px"
                                        cursor="pointer"
                                        width={header.getSize()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </Th>
                                ))}
                            </Tr>
                        );
                    })}
                </Thead>
                <Tbody>
                    <>
                        {isFetchingTableData && (
                            <Tr>
                                <Td colSpan={columns.length}>
                                    <Flex
                                        direction="column"
                                        alignItems="center"
                                        h="300px"
                                    >
                                        <Spinner variant={'pageLoader'} m="auto" />
                                    </Flex>
                                </Td>
                            </Tr>
                        )}
                        {!isFetchingTableData && errorTableData && (
                            <Tr>
                                <Td colSpan={columns.length}>
                                    <Flex h="53px" alignContent="center">
                                        <Text color="#979797" fontSize="16px" m="auto">
                                            Error fetching records
                                        </Text>
                                    </Flex>
                                </Td>
                            </Tr>
                        )}
                        {!isFetchingTableData && !errorTableData && (
                            <>
                                {flattenedData.length === 0 ? (
                                    <Tr>
                                        <Td colSpan={columns.length}>
                                            <Flex h="53px" alignContent="center">
                                                <Text
                                                    color="#979797"
                                                    fontSize="16px"
                                                    m="auto"
                                                >
                                                    No matching records found.
                                                </Text>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ) : (
                                    <>
                                        {table.getRowModel().rows.map(row => {
                                            return (
                                                <Tr key={row.id}>
                                                    {row.getVisibleCells().map(cell => {
                                                        return (
                                                            <Td
                                                                key={cell.id}
                                                                padding="16px"
                                                                boxShadow="0px 1px 0px #E6E6E6"
                                                                width={cell.column.getSize()}
                                                            >
                                                                {flexRender(
                                                                    cell.column.columnDef
                                                                        .cell,
                                                                    cell.getContext()
                                                                )}
                                                            </Td>
                                                        );
                                                    })}
                                                </Tr>
                                            );
                                        })}
                                    </>
                                )}
                            </>
                        )}
                    </>
                </Tbody>
            </Table>
            <TablePagination
                inputData={flattenedData}
                hasNextBtn={tableData && tableData.nextPage && tableData.nextPage !== ''}
                pageNum={pageNum}
                rowLimit={rowLimit}
                setRowLimit={setRowLimit}
                setPageToken={setPageToken}
                setPageNum={setPageNum}
            />
        </>
    );
};

export default ReactTable;
