import * as React from 'react';

const CheckListSvg = () => (
    <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.80467 8.69531L5.33333 10.224L8.19533 7.36198L9.138 8.30465L5.33333 12.1093L2.862 9.63798L3.80467 8.69531Z"
            fill="#111111"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33333 1.83333H10.6667C11.402 1.83333 12 2.43067 12 3.16667V15.1667C12 15.902 11.402 16.5 10.6667 16.5H1.33333C0.598 16.5 0 15.902 0 15.1667V3.16667C0 2.43067 0.598 1.83333 1.33333 1.83333H2.66667V1.16667C2.66667 0.798667 2.96533 0.5 3.33333 0.5C3.70133 0.5 4 0.798667 4 1.16667V1.83333H5.33333V1.16667C5.33333 0.798667 5.632 0.5 6 0.5C6.368 0.5 6.66667 0.798667 6.66667 1.16667V1.83333H8V1.16667C8 0.798667 8.29867 0.5 8.66667 0.5C9.03467 0.5 9.33333 0.798667 9.33333 1.16667V1.83333ZM1.33333 3.16667V15.1667H10.6673L10.6667 3.16667H9.33333V3.83333C9.33333 4.202 9.03467 4.5 8.66667 4.5C8.29867 4.5 8 4.202 8 3.83333V3.16667H6.66667V3.83333C6.66667 4.202 6.368 4.5 6 4.5C5.632 4.5 5.33333 4.202 5.33333 3.83333V3.16667H4V3.83333C4 4.202 3.70133 4.5 3.33333 4.5C2.96533 4.5 2.66667 4.202 2.66667 3.83333V3.16667H1.33333Z"
            fill="#111111"
        />
    </svg>
);

export default CheckListSvg;
