const init = () => {
    window.dataLayer = window.dataLayer || [];

    console.info('Analytics was initialized', window.dataLayer);
};

export enum ANALYTICS_EVENTS {
    REVIEWER_APPROVED = 'Reviewer Approved',
    REVIEWER_REJECTED = 'Reviewer Rejected',
    OCR_IMEI = 'OCR - IMEI',
    AI_IMEI = 'AI Predictions - IMEI',
    AI_FRONT = 'AI Predictions - Front',
    AI_BACK = 'AI Predictions - Back',
}

const eventNamePrefix = 'Console_DL_Event';

interface RequiredProps {
    smartscanId: string;
    system: string;
    partner: string;
    program: string;
}
const event = (event: ANALYTICS_EVENTS, data: RequiredProps & Record<string, any>) => {
    const { system, partner, program, smartscanId, ...otherData } = data;
    if (window?.dataLayer?.length >= 0) {
        const newEvent = {
            SmartScanID: smartscanId,
            EventName: `${eventNamePrefix} ${event}`,
            event: `${eventNamePrefix} ${event}`,
            Timestamp: new Date().toISOString(),
            System: system,
            Partner: partner,
            Program: program,
            ...otherData,
        };
        window.dataLayer.push(newEvent);

        console.info('GTM event added', newEvent);
    } else {
        console.error('window.dataLayer not initialized.');
    }
};

const analytics = { init, event };

export default analytics;
