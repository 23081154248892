import { Box, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AppFeatures } from '../../../config/config';
import { isFeatureEnabled } from '../../../lib/helpers';
import DetailEditableText from './DetailEditableText';

const DetailSerialNumberVerifyField: React.FC<{
    recordId: string;
    reviewer: string;
    status: string;
    serialNumber: string;
    onUpdate?: Function;
}> = ({ recordId, serialNumber, reviewer = '', status, onUpdate = () => {} }) => {
    const [errorMsg, setErrorMsg] = useState<any>([]);

    const enableValidation = isFeatureEnabled(AppFeatures.SERIAL_NUMBER_UPDATE_VALIDATION);
    return (
        <Flex direction={'row'} id={`serial-number-${recordId}`}>
            <Box>
                <DetailEditableText
                    recordId={recordId}
                    reviewer={reviewer}
                    fieldKey={'serialNumber'}
                    value={serialNumber}
                    status={status}
                    validator={
                        enableValidation
                            ? {
                                  alphanumericOnly: true,
                              }
                            : {}
                    }
                    onChange={(id, fieldKey, fieldValue) => {
                        setErrorMsg([]);
                        onUpdate(id, fieldKey, fieldValue);
                    }}
                    onError={errorCodes => {
                        if (enableValidation) {
                            setErrorMsg([]);

                            let messages: any = [];

                            if (errorCodes.indexOf('ALPHANUMERIC_ONLY') > -1) {
                                messages.push(<Box>Serial number must be alphanumeric only.</Box>);
                            }

                            if (messages.length > 0) {
                                setErrorMsg(messages);
                            }
                        }
                    }}
                    onCancel={() => setErrorMsg([])}
                />
                {enableValidation && errorMsg && errorMsg.length > 0 && (
                    <Box color={'red'} fontSize={'14px'}>
                        {errorMsg}
                    </Box>
                )}
            </Box>
        </Flex>
    );
};

export default DetailSerialNumberVerifyField;
