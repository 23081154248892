import { Box, Container, Divider, Flex, Link, Spacer } from '@chakra-ui/react';
import React, { FC } from 'react';

import AsurionLogoSvg from '../assets/AsurionLogoSvg';
import SmartScanLogoSvg from '../assets/SmartScanLogoSvg';
import { AppRoutes } from '../config/config';
import HeaderMenu from './HeaderMenu';

const Header: FC<{ route: string; statusUpdate?: string }> = ({
    route,
    statusUpdate,
}) => {
    return (
        <Container variant={'header'} id="header-component">
            <Flex direction={'row'} w={'full'} h={'60px'} id="header-flex-wrapper">
                <Box w={'263px'} id="header-logo-wrapper">
                    <Flex direction={'row'} w={'full'}>
                        <Box w="69px" my={'19px'}>
                            <AsurionLogoSvg
                                width={'93.5px'}
                                height={'22px'}
                                fill="#fff"
                            />
                        </Box>
                        <Divider
                            orientation="vertical"
                            h={'28px'}
                            borderColor={'white'}
                            margin={'19px 16px'}
                        />
                        <Box w="217px" my={'12px'}>
                            <SmartScanLogoSvg />
                        </Box>
                    </Flex>
                </Box>
                <Spacer />

                {/* App Name Title */}
                <Box w={'auto'} textAlign="center" id="header-nav-link">
                    <Flex direction={'row'} w={'full'}>
                        <Box
                            p={'18px 15px'}
                            h={'100%'}
                            boxShadow={
                                route === AppRoutes.SEARCH || route === AppRoutes.INDEX
                                    ? 'inset 0px -4px 0px #2743CC'
                                    : 'none'
                            }
                        >
                            <Link variant={'headerNav'} href={AppRoutes.SEARCH}>
                                Dashboard
                            </Link>
                        </Box>
                        <Spacer />
                        <Box
                            p={'18px 15px'}
                            h={'100%'}
                            boxShadow={
                                route === AppRoutes.ENROLLMENT
                                    ? 'inset 0px -4px 0px #2743CC'
                                    : 'none'
                            }
                        >
                            <Link variant={'headerNav'} href={AppRoutes.ENROLLMENT}>
                                Review Console
                            </Link>
                        </Box>
                    </Flex>
                </Box>
                {/* App Name Title */}

                <Spacer />
                <Box w={'auto'}>
                    <HeaderMenu statusUpdate={statusUpdate} />
                </Box>
            </Flex>
        </Container>
    );
};

export default Header;
