import * as React from 'react';

const RejectScreenSvg = props => (
    <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx={24} cy={24} r={24} fill="#DB1B1B" />
        <circle cx={24} cy={24} r={13.4} stroke="#fff" strokeWidth={2} />
        <path d="M34.285 14.229 14.742 33.77" stroke="#fff" strokeWidth={2} />
    </svg>
);

export default RejectScreenSvg;
